import { useState } from 'react';

import { useAlert } from 'react-alert';
import { Chat } from '../../../api';

import Delete from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import './styles.css';

const UserTalk = (props) => {
    const alert = useAlert();

    const [modalDeleteVisible, setModalDeleteVisible] = useState(false);

    const deleteChat = async () => {
        let response = await Chat.delete(props?.id);

        if(response.status !== 'error') {
            alert.success("Chat encerrado com sucesso");
            window.location.reload();
        }
    }

    const style_edition = {
        maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 4,
        p: 4,
        overflowY: 'auto'
    };

    return(
        <li className="user" onClick={props?.onclick}>
            <Modal
                open={modalDeleteVisible}
                onClose={() => setModalDeleteVisible(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style_edition}>
                    <div className="row my-2">
                        <div className="col">
                            <span className="text-uppercase">Tem certeza que deseja excluir essa mensagem?</span>
                        </div>
                    </div>
                    <button className="btn btn-success btn-sm" onClick={deleteChat}>Sim</button>
                    <button className="btn btn-warning mx-2 btn-sm" onClick={() => setModalDeleteVisible(false)}>Voltar</button>
                </Box>
            </Modal>
            <img src={props?.src} alt="user"/>
            <span>{props?.userName}</span>
            <button className="bg-transparent border-0 close-button" onClick={() => setModalDeleteVisible(true)}><Delete/></button>
        </li>
    )
}

export default UserTalk;