import { useAlert } from 'react-alert';
import InputMask from 'react-input-mask';
import React, { useEffect, useState } from 'react';
import { mask, unMask } from 'remask';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import ResponsiveDrawer from "../../components/ResponsiveDrawer"
import User from "../../api/Users";

import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import AddCircleSharp from '@mui/icons-material/AddCircleSharp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const Users = () => {

    const alert = useAlert()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [users, setUsers] = useState([])
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [modalVisible, setModalVisible] = useState(false)
    const [edit, setEdit] = useState({});
    const [modalDelete, setModalDelete] = useState({});
    const [src, setSrc] = useState(null);
    const [profile, setProfile] = useState("");
    const [editOpen, setEditOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [urlProfile, setUrlprofile] = useState("");
    const [rgvalue, setRgValue] = useState("");
    const [srcFileImage, setSrcFileImage] = useState(null);
    const [cropperPhoto, setCropperPhoto] = useState();

    const [firstName, setFirstName] = useState(""); 
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [cpf, setCpf] = useState("");
    const [phone, setPhone] = useState("");
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("ROLE_DISTRIBUTOR");
    
    const onChange = ev => {
        setRgValue(mask(unMask(ev.target.value), ['9999999-9', '99.999.999-9']));
    }

    const loadUsers = async () => {
        let response = await User.getAll()
        if (response.status !== "error") {
            setUsers(response.data.users)
        }
    }

    const createUser = async (event) => {
        event.preventDefault();

        var flag = 0;

        let obj = {
            cpf,
            email,
            firstName,
            lastName,
            password,
            rg: rgvalue,
            phone,
            username,            
            roles: [
                role
            ]
        }

        if (obj.rg.length < 9 || obj.cpf.length < 14) {
            flag = 1;
        }

        users.forEach((element) => {
            if (element.email === obj.email) {
                alert.error("Email já cadastrado")
                flag = 1;
            }
        })

        users.forEach((element) => {
            if (element.cpf === obj.cpf) {
                alert.error("CPF já cadastrado")
                flag = 1;
            }
        })
        if (flag < 1) {
            let response = await User.create(obj)
            if (response.status !== 'error') {
                alert.success("Cadastro efetuado")
                loadUsers();
                handleClose();
            } else {
                try {
                    Object.keys(response.data).forEach(k => {
                        alert.error(response.data[k]);
                    });
                } catch (error) {
                    alert.error(response.message);
                }
            }
        } else {
            alert.error("Existe campo(s) faltando, por favor, complete o formulário")
        }
    }

    const clearFields = () => {
        document.getElementById('userForm').reset()
    }

    const confirmRemoveData = (item) => {
        setModalDelete(item)
    }

    const doUpload = (event) => {
        let formData = new FormData();
        formData.append("image", event);
    
        fetch('https://api.imgur.com/3/image', {
          method: 'POST',
          headers: {
            Authorization: "Client-ID a2191559833e11f"
          },
          body: formData,
          redirect: 'follow'
        }).then(data => data.json()).then((result) => {
          setUrlprofile(result.data.link)
        })
    }
    
    const handleFileChange = e => {
        var file = e.target.files[0];
        var reader = new FileReader();
    
        reader.onloadend = function () {
          setSrc(reader.result);
          setProfile(reader.result);
          setEditOpen(true);
        }
    
        if (file) {
          reader.readAsDataURL(file);
        } else {
          setProfile("");
        }
    }
    
    function getRoundedCanvas(sourceCanvas) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var width = sourceCanvas.width;
        var height = sourceCanvas.height;
    
        canvas.width = width;
        canvas.height = height;
        context.imageSmoothingEnabled = true;
        context.drawImage(sourceCanvas, 0, 0, width, height);
        context.globalCompositeOperation = 'destination-in';
        context.beginPath();
        context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
        context.fill();
        return canvas;
    }
    
    const getCropData = () => {
        if (typeof cropperPhoto !== "undefined") {
          let croppedCanvas = cropperPhoto.getCroppedCanvas();
          let roundedCanvas = getRoundedCanvas(croppedCanvas);
          setSrcFileImage(roundedCanvas.toDataURL());
          setProfile(roundedCanvas.toDataURL());
          roundedCanvas.toBlob(function (data) {
            let file = new File([data], 'image.png')
            doUpload(file)
          })
          setEditOpen(false)
        }
    };

    const editUser = async (event) => {
        event.preventDefault();

        let obj = {
            id: edit.id,
            cpf: edit.cpf,
            email: edit.email,
            firstName: edit.firstName,
            lastName: edit.lastName,
            password: edit.password,
            profilePicture: urlProfile,
            rg: edit.rg,
            phone: edit.phone,
            roles: [role]
        }


        if (obj.rg.length < 9 || obj.cpf.length < 14) {
            alert.error("Campos restante, por favor, preencha-os")
        } else {
            let response = await User.update(obj)
            if (response.status !== 'error') {
                alert.success("Usuário editado com sucesso")
                loadUsers();
                setModalVisible(false)
            } else {
                alert.error("Verifique os campos")
            }
        }
    }

    function formatRole(role) {
        if(role === "ROLE_ADMIN") {
            return "ADMINISTRADOR"
        } else if (role === "ROLE_DISTRIBUTOR") {
            return "DISTRIBUIDOR"
        } else if (role === "ROLE_VIRTUAL_ACCOUNT") {
            return "CONTA VIRTUAL"
        } else if (role === "ROLE_END_USER") {
            return "USUÁRIO FINAL"
        } else if (role === " ROLE_MONITOR_ONLY") {
            return "SOMENTE VISUALIZAÇÃO"
        }
    }

    const deleteUser = async (id) => {
        let response = await User.delete(id);
        if (response.status !== 'error') {
            loadUsers();
            setModalDelete(false)
        }
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'firstName', headerName: 'Primeiro Nome', width: 130 },
        { field: 'lastName', headerName: 'Último Nome', width: 130 },
        {
            field: 'email',
            headerName: 'Email',
            width: 200
        },
        {
            field: 'cpf',
            headerName: 'CPF',
            width: 150,
            renderCell: (params) => {
                if (!params.value) return;
                if (!params.value.includes('.')) {
                    return (
                        <span>{params.value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</span>
                    )
                }
            }
        },
        {
            field: 'roles',
            headerName: 'Permissão',
            width: 130,
            renderCell: (params) => {
                return (
                    <span>{formatRole(params.formattedValue[0])}</span>
                )
            }
        },
        {
            field: ' ',
            sortable: false,
            width: 130,
            renderCell: (params) => {
                return (
                    <div>
                        <button className="btn" onClick={() => {
                            setModalVisible(true);
                            setEdit(params.row);
                        }}>
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button className="btn" onClick={() => confirmRemoveData(params.row)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </div>

                )
            }
        }
    ];

    const closeModal = () => {
        setEditOpen(false);
        setProfile("");
    }
    
    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <div>
            <ResponsiveDrawer>
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <button className="btn btn-primary button" onClick={() => {
                                            handleOpen()
                                            setRgValue('')
                                        }}>Adicionar um novo usuário</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div style={{ width: '100%' }}>
                                    <DataGrid
                                        autoHeight
                                        rows={users}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        disableColumnSelector
                                        disableColumnFilter
                                        disableColumnMenu
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="row my-2">
                            <div className="col">
                                <span className="text-uppercase">Cadastro de um Novo usuário</span>
                            </div>
                        </div>
                       
                        <div className="row">
                            <div>
                            <input
                                type="file"
                                id="file"
                                className="input"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                    handleFileChange(event);
                                    setImage(event);
                                }}
                            />
                            
                            {
                                urlProfile === "" ? (
                                <div className="upload-photo">
                                    <label for="file" id="label-file">
                                    <AddCircleSharp />
                                    <p>Foto de perfil</p>
                                    </label>
                                </div>
                                ) : (
                                <div className="upload-photo">
                                    <label for="file" id="reupload-file">
                                    <img src={profile} alt="" className="reupload-photo-img" />
                                    </label>
                                </div>
                                )
                            }
        
                            {
                                editOpen && (
                                    <div className="cropper-container">
                                        <Cropper
                                            className="cropper-container"
                                            aspectRatio={1}
                                            preview=".img-preview"
                                            src={profile}
                                            viewMode={1}
                                            guides={true}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false}
                                            onInitialized={(instance) => setCropperPhoto(instance)}
                                        />
                                        <button className="btn btn-danger my-2 mx-2 text-center" onClick={getCropData}>
                                            Salvar
                                        </button>

                                        <button className="btn btn-warning my-2 mx-2 text-center" onClick={closeModal}>
                                            Fechar
                                        </button>
                                    </div>
                                )
                            }

                            </div>
                            
                            <form onSubmit={createUser}>
                                <div className="d-flex justify-content-between">
                                    <div className="col-md-6 col-sm-6 my-2">
                                        <label>Primeiro Nome</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Primeiro nome" 
                                            onChange={(event) => setFirstName(event.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-12 my-2">
                                        <label>Segundo Nome</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Segundo nome" 
                                            onChange={(event) => setLastName(event.target.value)}
                                        />
                                    </div>
                                </div>
                              
                                <div className="d-flex justify-content-between">
                                    <div className="col-md-6 col-sm-12 my-2">
                                        <label>Nome de usuário</label>
                                        <input
                                            required
                                            type="Nome de usuário"
                                            className="form-control"
                                            placeholder="Nome de usuário" 
                                            onChange={(event) => setUsername(event.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-12 my-2">
                                        <label>Email</label>
                                        <input
                                            required
                                            type="email"
                                            className="form-control"
                                            placeholder="Email" 
                                            onChange={(event) => setEmail(event.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <div className="col-md-6 col-sm-12 my-2">
                                        <label>CPF</label>
                                        <InputMask
                                            required
                                            id="cpf-field"
                                            placeholder="CPF"
                                            mask="999.999.999-99"
                                            className="contact form-control"
                                            min="14"
                                            onChange={(event) => setCpf(event.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 my-2">
                                        <label>RG</label>
                                        <InputMask
                                            type="text"
                                            className="form-control"
                                            placeholder="RG"
                                            required
                                            onChange={onChange}
                                            value={rgvalue}
                                            min="8"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="col-md-6 col-sm-12 my-2">
                                        <label>Celular</label>
                                        <InputMask
                                            id="phone-field"
                                            placeholder="Celular"
                                            mask="(999) 99999-9999"
                                            className="contact form-control"
                                            onChange={(event) => setPhone(event.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-6 col-sm-12 my-2">
                                        <label>Senha</label>
                                        <input
                                            required
                                            type="password"
                                            className="form-control"
                                            placeholder="Digite sua senha" 
                                            onChange={(event) => setPassword(event.target.value)}
                                        />
                                    </div>
                                </div>
                                <select onChange={(event) => setRole(event.target.value)} className="form-control">
                                    <option value="ROLE_DISTRIBUTOR">ROLE_DISTRIBUTOR</option>
                                    <option value="ROLE_VIRTUAL_ACCOUNT">ROLE_VIRTUAL_ACCOUNT</option>
                                    <option value="ROLE_END_USER">ROLE_END_USER</option>
                                    <option value="ROLE_MONITOR_ONLY">ROLE_MONITOR_ONLY</option>
                                </select>
                              
                                <div className="col-md-6 col-sm-12 my-2">
                                    <button type="submit" className="btn btn-success btn-sm"> Cadastrar </button>
                                    <button type="button" className="btn btn-warning mx-2 btn-sm" onClick={handleClose}> Fechar </button>
                                </div>
                            </form>
                            
                        </div>
                    </Box>
                </Modal>
            
                <Modal
                    open={modalVisible}
                    onClose={() => {
                        setEdit({})
                        clearFields()
                        setModalVisible(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="row my-2">
                            <div className="col">
                                <span className="text-uppercase">Edição</span>
                            </div>
                        </div>
                        <form id="userForm" onSubmit={editUser}>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 my-2">
                                    <label>Primeiro Nome</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Primeiro nome"
                                        value={edit.firstName}
                                        onChange={(e) => setEdit({ ...edit, firstName: e.target.value })}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-12 my-2">
                                    <label>Segundo Nome</label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Segundo nome"
                                        value={edit.lastName}
                                        onChange={(e) => setEdit({ ...edit, lastName: e.target.value })} />
                                </div>
                                <div className="col-md-6 col-sm-12 my-2">
                                    <label>Email</label>
                                    <input
                                        required
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        value={edit.email}
                                        onChange={(e) => setEdit({ ...edit, email: e.target.value })}
                                    />
                                </div>

                                <div className="col-md-6 col-sm-12 my-2">
                                    <label>CPF</label>
                                    <InputMask
                                        required={true}
                                        id="cpf-field"
                                        placeholder="CPF"
                                        mask="999.999.999-99"
                                        className="contact form-control"
                                        value={edit.cpf}
                                        onChange={(e) => setEdit({ ...edit, cpf: e.target.value })}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-12 my-2">
                                    <label>RG</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="RG"
                                        required
                                        value={edit.rg}
                                        onChange={(e) => setEdit({ ...edit, rg: e.target.value })}
                                    />
                                </div>
                                <div className="col-md-6 col-sm-12 my-2">
                                    <label>Celular</label>
                                    <InputMask
                                        id="phone-field"
                                        placeholder="Celular"
                                        mask="(999) 99999-9999"
                                        className="contact form-control"
                                        value={edit.phone}
                                        onChange={(e) => setEdit({ ...edit, phone: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 my-2">
                                <button type="submit" className="btn btn-success btn-sm"> Editar </button>
                                <button className="btn btn-warning mx-2 btn-sm" onClick={() => {
                                    setModalVisible(false)
                                    setEdit({})
                                }}> Fechar </button>
                            </div>
                        </form>
                    </Box>
                </Modal>
    
                <Modal
                    open={modalDelete.firstName ? true : false}
                    onClose={() => setModalDelete({})}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="row my-2">
                            <div className="col">
                                <span className="text-uppercase">Você deseja excluir o usuário {modalDelete.firstName}? </span>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col">
                                <button className="btn btn-success btn-sm" onClick={() => deleteUser(modalDelete.id)}> Sim </button>
                                <button className="btn btn-warning mx-2 btn-sm" onClick={() => setModalDelete({})}> Não </button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </ResponsiveDrawer>
        </div>
    )
}

export default Users;
