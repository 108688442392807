import { Filter, ResponsiveDrawer, ButtonAsaas } from "../../components";
import { COLORS, COLUMNS, handleFilter } from "../../utils";
import AssaSAPI from "../../api/AssaS";

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useAlert } from 'react-alert';
import InputMask from 'react-input-mask';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faInfoCircle, faPowerOff } from '@fortawesome/free-solid-svg-icons'

const AssaS = () => {

    const alert = useAlert()

    const style = {
        maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        left: '0%',
        transform: 'translate(0, -50%)',
        width: '100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    };

    const style_edition = {
        maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    };

    const [users, setUsers] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("");
    const [automaticModeIndex, setAutomaticModeIndex] = useState();
    const [daysAutomaticModeOff, setDaysAutomaticModeOff] = useState(0);

    const [modalVisible, setModalVisible] = useState(false)
    const [edit, setEdit] = useState({});
    const [modalInfoVisible, setModalInfoVisible] = useState(false);
    const [info, setInfo] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [modalAutomaticModeDaysVisible, setModalAutomaticModeDaysVisible] = useState(false);

    const blockUser = async (usr) => {
        if (usr.externalReference !== null) {
            var urlencoded = new URLSearchParams();
            urlencoded.append("externalReference", usr.externalReference);

            let response = await AssaSAPI.blockUser(urlencoded);
            if (response.status !== 'error') {
                alert.success("Desativado!");
                loadUsers();
            }
        } else {
            alert.info("Por favor, prencha o campo de referência externa!")
        }
    }

    const unBlockUser = async (usr) => {
        if (usr.externalReference !== null) {
            var urlencoded = new URLSearchParams();
            urlencoded.append("externalReference", usr.externalReference);

            let response = await AssaSAPI.unBlockUser(urlencoded);
            if (response.status !== 'error') {
                alert.success("Ativado!");
                loadUsers();
            }
        } else {
            alert.info("Por favor, prencha o campo de referência externa!")
        }
    }

    const automaticModeOn = async (usr) => {
        if (usr.disableAutomatic !== null) {
            var urlencoded = new URLSearchParams();
            urlencoded.append("userId", usr.id);

            let response = await AssaSAPI.automaticModeOn(urlencoded);
            if (response.status !== 'error') {
                alert.success("Ativado!");
                loadUsers();
            }
        } else {
            alert.info("Por favor, prencha o campo de referência externa!")
        }
    }

    const automaticModeOff = async (usr, daysOff) => {
        if (usr.disableAutomatic !== null) {
            var urlencoded = new URLSearchParams();
            urlencoded.append("userId", usr.id);
            urlencoded.append("numberOfDays", daysOff);

            let response = await AssaSAPI.automaticModeOff(urlencoded);
            if (response.status !== 'error') {
                alert.success("Desativado!");
                loadUsers();
            }
           
        } else {
            alert.info("Por favor, prencha o campo de referência externa!")
        }
    }


    const activeNotification = async (usr) => {
        if (usr.notificationDisabled !== null) {
            var urlencoded = new URLSearchParams();
            urlencoded.append("externalReference", usr.externalReference);

            let data = {
                "userId": usr.id,
                "status": 1
            }

            var formBody = [];
            for (var property in data) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(data[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            let response = await AssaSAPI.activeNotification(formBody);
            if (response.status !== 'error') {
                alert.success("Ativado!")
                loadUsers()
            }

        } else {
            alert.info("Por favor, prencha o campo de referência externa!")
        }
    }

    const unableNotification = async (usr) => {
        if (usr.notificationDisabled !== null) {
            var urlencoded = new URLSearchParams();
            urlencoded.append("externalReference", usr.externalReference);
            let data = {
                "userId": usr.id,
                "status": 0
            }

            var formBody = [];
            for (var property in data) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(data[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            let response = await AssaSAPI.unableNotification(formBody);
            if (response.status !== 'error') {
                alert.success("Desativado!");
                loadUsers();
            }
        } else {
            alert.info("Por favor, prencha o campo de referência externa!")
        }
    }

    const loadUsers = async (page) => {
        let response = await AssaSAPI.getAllUsers();
        if (response.status !== 'error') {
            setUsers(response.data);
        }
    }

    const editExternalField = async (event) => {
        event.preventDefault();
        var details = {
            'userId': edit.id,
            'externalField': event.target[0].value,
        };

        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        let response = await AssaSAPI.update(formBody);
        if (response.status !== 'error') {
            alert.success("Referência externa atualizada")
            loadUsers(0);
            setModalVisible(false)
        } else {
            alert.error(response.message || "Erro. Tente novamente.")
        }
    }

    const REFERENCE_EDIT = {
        name: 'Edição de Referência',
        cell: index => {
          return (
              <div>
                  <button className="btn" title="Editar" onClick={() => {
                      setModalVisible(true);
                      setEdit(index);
                  }}>
                      <FontAwesomeIcon icon={faEdit} />
                  </button>
              </div>
      
          )
        }
    }

    const GENERAL_INFORMATION = {
        name: 'Informações Gerais',
        cell: index => {
            return (
                <div>
                    <button className="btn" title="Informações Gerais" onClick={() => {
                        setModalInfoVisible(true)
                        setInfo(index)
                    }}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </button>
                </div>

            )
        }
    }

    const TRACKING = {
        name: 'Rastreamento',
        selector: row => row.name,
        cell: index => {
            return (
                <div>
                    {index.isBlocked == true ?
                        <ButtonAsaas 
                            title="Ativar Rastreamento" 
                            bgColor="bg-danger" 
                            textColor="text-danger"
                            onclick={() => {
                                setEdit(index);
                                unBlockUser(index);
                            }}
                        >
                            <FontAwesomeIcon icon={faPowerOff} />
                        </ButtonAsaas>
                        :
                        <ButtonAsaas 
                            title="Desativar Rastreamento" 
                            bgColor="bg-success" 
                            textColor="text-success"
                            onclick={() => {
                                setEdit(index);
                                blockUser(index);
                            }}
                        >
                            <FontAwesomeIcon icon={faPowerOff} />
                        </ButtonAsaas>                          
                    }<br />
                </div>
            )
        }
    }

    const AUTOMATIC_MODE  = {
        name: 'Modo Automático',
        selector: row => row.name,
        cell: index => {
            return (
                <div>
                    {index.disableAutomatic == true ?
                        <ButtonAsaas 
                            title="Ativar modo automático" 
                            bgColor="bg-danger" 
                            textColor="text-danger"
                            onclick={() => {
                                setEdit(index);
                                automaticModeOn(index);
                            }}
                        >
                            <FontAwesomeIcon icon={faPowerOff} />
                        </ButtonAsaas>
                        :
                        <ButtonAsaas 
                            title="Desativar modo automático" 
                            bgColor="bg-success" 
                            textColor="text-success"
                            onclick={() => {
                                setEdit(index);
                                setAutomaticModeIndex(index);
                                setModalAutomaticModeDaysVisible(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faPowerOff} />
                        </ButtonAsaas>    
                    }<br />

                </div>

            )
        }
    }

    const NOTIFICATION = {
        name: 'Notificações',
        selector: row => row.name,
        cell: index => {
            return (
                <div>
                    {index.notifyOverdue === false ?
                        <ButtonAsaas 
                            title="Ativar notificações" 
                            bgColor="bg-danger" 
                            textColor="text-danger"
                            onclick={() => {
                                setEdit(index);
                                activeNotification(index);
                            }}
                        >
                            <FontAwesomeIcon icon={faPowerOff} />
                        </ButtonAsaas>
                        :
                        <ButtonAsaas 
                            title="Desativar notificações" 
                            bgColor="bg-success" 
                            textColor="text-success"
                            onclick={() => {
                                setEdit(index);
                                unableNotification(index);
                            }}
                        >
                            <FontAwesomeIcon icon={faPowerOff} />
                        </ButtonAsaas>    
                    }<br />

                </div>

            )
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
    };

    useEffect(() => {
        loadUsers(0);
    }, [])

    return (
        <div>
            <ResponsiveDrawer>
                <div className="row">
                    <div className="col">
                        <div className="card">
                           
                            <div className="card-body">
                                <div className="d-flex flex-row-reverse my-2">
                                    <div className="bg-success text-white px-2 py-2 mx-2">Ativo</div>
                                    <div className="bg-danger text-white px-2 py-2 w-5">Inativo</div>   
                                </div>
                                
                                <div style={{ width: '100%' }}>
                                    <input
                                        onChange={(event) => {
                                            setSearchTerm(event.target.value)
                                        }}
                                        placeholder="Pesquisar por nome ou e-mail"
                                        className="form-control"
                                    />
                                    <DataTable
                                        columns={[
                                            { ...COLUMNS.NAME },
                                            { ...COLUMNS.EMAIL },
                                            { ...COLUMNS.EXTENAL_REFERENCE },
                                            REFERENCE_EDIT,
                                            GENERAL_INFORMATION,
                                            TRACKING,
                                            AUTOMATIC_MODE,
                                            NOTIFICATION
                                        ]}
                                        noDataComponent="Não há dados para mostrar" 
                                        data={handleFilter(users, selectedFilter, "dueDate", searchTerm)}
                                        pagination={true}
                                        paginationComponentOptions={paginationComponentOptions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Modal
                        open={modalVisible}
                        onClose={() => {
                            setEdit({})
                            setModalVisible(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style_edition}>
                            <div className="row my-2">
                                <div className="col">
                                    <span className="text-uppercase">Edição</span>
                                </div>
                            </div>
                            <form id="userForm" onSubmit={editExternalField}>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 my-2">
                                        <label>Referência Externa</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Referência Externa"
                                            value={edit.externalReference}
                                            onChange={(e) => setEdit({ ...edit, externalReference: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 my-2">
                                    <button type="submit" className="btn btn-success" style={{ height: '40px' }}> Editar </button>
                                    <button className="btn btn-warning mx-2" style={{ height: '40px' }} onClick={() => {
                                        setModalVisible(false)
                                        setEdit({})
                                    }}> Fechar </button>
                                </div>
                            </form>
                        </Box>
                    </Modal>
                </div>

                <div>
                    <Modal
                        open={modalInfoVisible}
                        onClose={() => {
                            setInfo({})
                            setModalInfoVisible(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className="row my-2">
                                <div className="col">
                                    <span className="text-uppercase">Informações Gerais do usuário {info.name}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Nome</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Nome Completo"
                                        value={info.name}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>CPF</label>
                                    <InputMask
                                        id="cpf-field"
                                        placeholder="CPF"
                                        mask="999.999.999-99"
                                        className="contact form-control"
                                        value={info.cpfCnpj}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        value={info.email}
                                        disabled />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Telefone Fixo</label>
                                    <InputMask
                                        placeholder="Celular"
                                        mask="(99) 9999-9999"
                                        className="contact form-control"
                                        value={info.phone}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Celular</label>
                                    <InputMask
                                        placeholder="Celular"
                                        mask="(99) 99999-9999"
                                        className="contact form-control"
                                        value={info.mobilePhone}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Código Postal</label>
                                    <InputMask
                                        placeholder="CEP"
                                        mask="99999-999"
                                        className="contact form-control"
                                        value={info.postalCode}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Endereço</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Endereço"
                                        value={info.address}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Numero</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Nº"
                                        value={info.addressNumber}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Complemento</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Nº"
                                        value={info.complement}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Provincia</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Provincia"
                                        value={info.province}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Emails Adicionais</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Provincia"
                                        value={info.additionalEmails}
                                        disabled
                                    />
                                </div>

                                {
                                    info.groups !== undefined ? (
                                        <div className="col-md-3 col-sm-12 my-2">
                                            <label>Grupos</label>
                                            <select className="form-control">
                                                {
                                                    info.groups.map(grupo => (
                                                        <option key={grupo.id} value={grupo.id}>{grupo.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    ) : (
                                        <div className="col-md-3 col-sm-12 my-2">
                                            <label>Grupos</label>
                                            <select className="form-control" disabled>
                                                <option value={null}>Não há grupo cadastrado</option>
                                            </select>
                                        </div>
                                    )
                                }

                            </div>
                            <div className="col-md-6 col-sm-12 my-2">
                                <button className="btn btn-warning" style={{ height: '40px' }} onClick={() => {
                                    setModalInfoVisible(false);
                                    setInfo({})
                                }}> Fechar </button>
                            </div>
                        </Box>
                    </Modal>
                </div>

                <div>
                    <Modal
                        open={modalAutomaticModeDaysVisible}
                        onClose={() => {
                            setModalAutomaticModeDaysVisible(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className="row my-2">
                                <div className="col">
                                    <span className="text-uppercase">Quantos dias deseja que o modo automático fique desativado</span>
                                </div>
                            </div>
                            <div className="row">
                                <input type="number" min="1" onChange={(event) => setDaysAutomaticModeOff(event.target.value)}/>
                            </div>
                            <div className="col-md-6 col-sm-12 my-2">
                                <button className="btn btn-warning" style={{ height: '40px' }} onClick={() => {
                                    automaticModeOff(automaticModeIndex, daysAutomaticModeOff);
                                    setModalAutomaticModeDaysVisible(false);
                                }}> Salvar </button>
                            </div>
                        </Box>
                    </Modal>
                </div>
            </ResponsiveDrawer>
        </div>
    )
}

export default AssaS