import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useAlert } from "react-alert";

import ResponsiveDrawer from "../../components/ResponsiveDrawer";
import { NotificationsAPI } from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Box, Modal } from "@mui/material";

const Notifications = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationToEdit, setNotificationToEdit] = useState({});

  const alert = useAlert();

  const loadData = async () => {
    const response = await NotificationsAPI.getAll();
    if (response.status !== "error") setNotifications(response.data);
    else alert.error(response.message);
  };

  useEffect(() => { loadData(); }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await NotificationsAPI.update(notificationToEdit);
    if (response.status !== "error") {
      loadData();
      setIsModalOpen(false);
      setNotificationToEdit({});
    }
    else alert.error(response.message);
  };

  return (
    <>
      <ResponsiveDrawer>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div style={{ width: '100%' }}>
                  <DataGrid
                    autoHeight
                    rows={notifications}
                    columns={[
                      { field: 'description', headerName: 'Descrição', width: 200 },
                      { field: 'minDay', headerName: 'Dia', width: 60 },
                      { field: 'maxDay', headerName: 'Dia', width: 60 },
                      { field: 'pushMessage', headerName: 'Mensagem Push', width: 250 },
                      { field: 'mailMessage', headerName: 'Mensagem E-mail', width: 250 },
                      { field: 'smsMessage', headerName: 'Mensagem SMS', width: 250 },
                      {
                        field: 'id', headerName: '', width: 60, renderCell: (params) => {
                          return (
                            <button className="btn" onClick={() => {
                              setIsModalOpen(true);
                              setNotificationToEdit(params.row);
                            }}>
                              <FontAwesomeIcon icon={faEdit} />
                            </button>

                          )
                        }
                      },
                    ]}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnMenu
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ResponsiveDrawer>

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setNotificationToEdit({});
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <div className="row my-2">
            <div className="col">
              <span className="text-uppercase">Edição de Notificação</span>
            </div>
          </div>

          <div className="row mt-4">
            <form onSubmit={handleSubmit}>
              <div className="d-flex gap-4">
                <div className="col-5">
                  <label>Descrição</label>
                  <input
                    required
                    value={notificationToEdit.description}
                    className="form-control"
                    placeholder="Descrição"
                    onChange={(e) => {
                      setNotificationToEdit((prev) => {
                        return {
                          ...prev,
                          description: e.target.value
                        };
                      })
                    }}
                  />
                </div>
                <div className="col-3">
                  <label>Dia Inicial</label>
                  <input
                    required
                    value={notificationToEdit.minDay}
                    type="number"
                    className="form-control"
                    placeholder="Dia Inicial"
                    onChange={(e) => {
                      setNotificationToEdit((prev) => {
                        return {
                          ...prev,
                          minDay: e.target.value
                        };
                      })
                    }}
                  />
                </div>
                <div className="col-3">
                  <label>Dia Final</label>
                  <input
                    required
                    value={notificationToEdit.maxDay}
                    type="number"
                    className="form-control"
                    placeholder="Dia Final"
                    onChange={(e) => {
                      setNotificationToEdit((prev) => {
                        return {
                          ...prev,
                          maxDay: e.target.value
                        };
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-12 my-2">
                <label>Mensagem Push</label>
                <textarea
                  required
                  value={notificationToEdit.pushMessage}
                  className="form-control"
                  placeholder="Push"
                  style={{ height: 120 }}
                  onChange={(e) => {
                    setNotificationToEdit((prev) => {
                      return {
                        ...prev,
                        pushMessage: e.target.value
                      };
                    })
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <label>Mensagem E-mail</label>
                <textarea
                  required
                  value={notificationToEdit.mailMessage}
                  className="form-control"
                  placeholder="E-mail"
                  style={{ height: 120 }}
                  onChange={(e) => {
                    setNotificationToEdit((prev) => {
                      return {
                        ...prev,
                        mailMessage: e.target.value
                      };
                    })
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <label>Mensagem SMS</label>
                <textarea
                  required
                  value={notificationToEdit.smsMessage}
                  className="form-control"
                  placeholder="SMS"
                  style={{ height: 120 }}
                  onChange={(e) => {
                    setNotificationToEdit((prev) => {
                      return {
                        ...prev,
                        smsMessage: e.target.value
                      };
                    })
                  }}
                />
              </div>
              <div className="d-flex gap-2">
                <button className="btn btn-success">Salvar</button>
                <button className="btn btn-warning">Fechar</button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Notifications;
