import ResponsiveDrawer from "../../components/ResponsiveDrawer";

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useAlert } from 'react-alert';

import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';

import ModalAdicionar from './ModalAdicionar';
import ModalDelete from './ModalDelete';
import ModalEditar from './ModalEditar';
import Button from './Button';

import Device from '../../api/Devices';

import { useStore } from 'react-redux';

const Devices = () => {
  const alert = useAlert();
  const store = useStore();

  const [searchTerm, setSearchTerm] = useState("");
  const [modalVisible, setModalVisible]  = useState(false);
  const [modalVisibleEdit, setModalVisibleEdit] = useState(false);
  const [modalVisibleDelete, setModalVisibleDelete] = useState(false);
  const [devices, setDevices] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [idDeviceClicked, setIdDeviceClicked] = useState("");

  let userData = store.getState().user.data;

  const getDeviceData = async (id) => {
    let response = await Device.getById(id);

    if(response.status !== 'error') {
      setDeviceData(response);
    }
  }
  const columnsDevices = [
    {
      name: 'Modelo',
      selector: row => row.model,
      sortable: true
    },
    {
      name: 'Tipo',
      selector: row => row.type,
      sortable: true
    },
    {
      name: 'Data de ativação',
      selector: row => row.activatedDate,
      sortable: true
    },
    {
      name: 'Data de expiração',
      selector: row => row.platformExpirationDate,
      sortable: true
    },
    {
      name: 'IMEI',
      selector: row => row.imeiId,
      sortable: true
    },
    {
      name: 'Velocidade',
      selector: row => row.speedingValue,
      sortable: true
    },
    {
      name: 'Observações',
      selector: row => row.remarks,
      sortable: true
    },
    {
      name: 'Data de venda',
      selector: row => row.sellDate,
      sortable: true
    },
    {
      name: 'Editar',
      selector: row => {
        return (
          <div>
            <button className="btn" title="Editar" onClick={() => {
              getDeviceData(row.id);
              getDeviceData(row.id);
              setModalVisibleEdit(true);
            }}>
              <Edit />
            </button>
          </div>
        )
      }
    },
    {
      name: 'Delete',
      selector: row => {
        return (
          <div>
            <button className="btn" title="Deletar" onClick={() => {
              setModalVisibleDelete(true);
                setIdDeviceClicked(row.id);
            }}>
              <Delete />
            </button>
          </div>
        )
      }
    },
  ];
 
  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
  };

  const getDevices = async () => {
    let response = await Device.getAll();
    setDevices(response.data);
  }

  useEffect(() => {
    getDevices();
  }, []);

  return (
      <div>
        <ResponsiveDrawer>
            <div className="row">
              <div className="col">
                 <div className="card">
                    <div className="row my-2 p-4">
                      <div className="w-100">
                        <input
                          onChange={(event) => setSearchTerm(event.target.value)}
                          placeholder="Pesquisar por nome"
                          className="form-control"
                        />
                        <DataTable
                          columns={columnsDevices}
                          noDataComponent="Não há dados para mostrar"
                          data={devices.filter((item) => {
                            if (searchTerm === "") {
                                return item;
                            }else if(item.model.toLowerCase().includes(searchTerm.toLowerCase())){
                                return item;
                            }else if(item.speedingValue.toString().toLowerCase().includes(searchTerm.toLowerCase())){
                                return item;
                            }else if(item.remarks.toLowerCase().includes(searchTerm.toLowerCase())){
                                return item;
                            }
                          })}
                          pagination={true}
                          paginationComponentOptions={paginationComponentOptions}
                        />
                        <Button
                          icon={<Add/>}
                          name="Adicionar relatório"
                          event={() => setModalVisible(true)}
                        />
                    </div>
                </div>
              </div>   
            </div>
            <ModalAdicionar
              visible={modalVisible}
              setModalVisible={setModalVisible}
            />
            <ModalEditar
              visible={modalVisibleEdit}
              setModalVisible={setModalVisibleEdit}
              data={deviceData}
            />
            <ModalDelete
              visible={modalVisibleDelete}
              setModalVisible={setModalVisibleDelete}
              idDevice={idDeviceClicked}
            />
          </div>
        </ResponsiveDrawer>
      </div>
    )
}
export default Devices;
