import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import validator from 'validator';
import InputMask from 'react-input-mask';
import { useAlert } from 'react-alert';
import axios from 'axios';

import { store } from "../../redux/store";

import { handleFilter } from "../../utils";
import { Filter, ResponsiveDrawer } from "../../components";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Person from '@mui/icons-material/Person';
import LocalPhone from '@mui/icons-material/LocalPhone';
import Email from '@mui/icons-material/Email';
import Edit from '@mui/icons-material/Edit';
import Settings from '@mui/icons-material/Settings';
import AddCircleSharp from '@mui/icons-material/AddCircleSharp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import './styles.css';
import 'chart.js/auto';

export default function UserManager(){

    const alert = useAlert();

    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [modalConfigVisible, setModalConfigVisible] = useState(false);
    const [modalMessageVisible, setModalMessageVisible] = useState(false);
    const [modalInfoVisible, setModalInfoVisible] = useState(false);

    const [emailError, setEmailError] = useState('');
    const [src, selectFile] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [profile, setProfile] = useState("");
    const [image, setImage] = useState(null);
    const [edit, setEdit] = useState({});
    const [info, setInfo] = useState({});
    
    const [searchTerm, setSearchTerm] = useState("");
    const [users, setUsers] = useState([]);

    const [selectedFilter, setSelectedFilter] = useState("");

    const columns = [
        {
            name: 'Nome Completo',
            cell: row => {
                return (
                    <div>
                        <button className="btn" title="Informações Gerais" onClick={() => {
                            setModalInfoVisible(true)
                        }}>
                           {row.name}
                        </button>
                    </div>
                )
            },
            sortable: true
        },
        {
            name: 'E-mail',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Referência Externa',
            selector: row => row.externalReference,
            sortable: true
        },
        {
            name: 'Informações Gerais',
            cell: index => {
                return (
                    <div>
                        <button className="btn" title="Informações Gerais" onClick={() => {
                            setModalInfoVisible(true)
                            setInfo(index)
                        }}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </button>
                    </div>
                )
            }
        }
    ];
    
    const data = [
        {
            id: 1,
            idReport: '1',
            quilometrage: '0',
            fast: '0',
            stop: '0'
        },
        {
            id: 2,
            idReport: '2',
            quilometrage: '0',
            fast: '0',
            stop: '0'
        },
        
    ];
    
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
    };

    const style_edition = {
        maxWidth: '100vw',
        maxHeight: '100%',
        bgcolor: 'background.paper',
    };

    const style = {
        maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        left: '0%',
        transform: 'translate(0, -50%)',
        width: '100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    };

    const style_edition_modal = {
        maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    };

    const validateEmail = (e) => {
        var email = e.target.value
      
        if (validator.isEmail(email)) {
          setEmailError('')
        } else {
          setEmailError('Por favor, insira um email válido!')
        }
    }
      
    const handleFileChange = e => {
        var file = e.target.files[0];
        var reader = new FileReader();
    
        reader.onloadend = function () {
          selectFile(reader.result);
          setProfile(reader.result);
          setEditOpen(true)
        }
    
        if (file) {
          reader.readAsDataURL(file);
        } else {
          setProfile("");
        }
    }


    const loadUsers = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${store.getState().user.token}`
        }

        var url = process.env.REACT_APP_LINK_API;

        try {
            let response = await axios.get(url + `asaas/getAllUsers`, {
                headers: headers
            });

            setUsers(response.data);
           
        } catch (e) {
            alert.error("Erro, verifique as informações de acesso");
        }
    }

    useEffect(() => {
        loadUsers();
    }, [])

    return (
        <div>
            <ResponsiveDrawer>
                <div className="row d-flex">
                    <div className='card'>
                        <Box sx={style_edition}>
                            <div className="row my-2">
                                <div className="col">
                                    <Person/>
                                    <span>Mp Rastreamento</span>
                                </div>

                                <hr></hr>
                                
                                <div className='d-flex'>
                                    <div className='profile-general-informations'>
                                        <div className='d-flex align-items-center'>
                                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1-Ck9N4lWCL1brsaW6lkKDAu8zRJhH-k79-iD3BXSnYM365oVy-q13CUP5ZksoynXNx0&usqp=CAU" alt="profile"/>
                                            
                                            <ul id="data-user">
                                                <li>
                                                    <Person/>
                                                    <p>mpcar</p>
                                                </li>
                                                <li>
                                                    <LocalPhone/>
                                                    <p>(82) 4002-8922</p>
                                                </li>
                                                <li>
                                                    <Email/>
                                                    <p>mprastr@mp.com.br</p>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                        <div className='profile-user-actions'>
                                            <button onClick={() => setModalEditVisible(true)}  >
                                                <Edit/>
                                                <p>Editar</p>
                                            </button>
                                            <button onClick={() => setModalConfigVisible(true)}>
                                                <Settings/>
                                                <p>Configurações</p>
                                            </button>
                                            <button onClick={() => setModalMessageVisible(true)}>
                                                <Email/>
                                                <p>Enviar Mensagem</p>
                                            </button>
                                        </div>
                                    </div>

                                    <div className='device-quantity-profile-informations'>
                                        <span>Quantidade de dispositivos</span>
                                        <p>343</p>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </Box>   
                    </div>
                    <div className="card-body">

                        <div className='container-filter-reports'>
                            <p><b>Filtros</b></p>
                            <Filter onClick={(item) => setSelectedFilter(item)} />
                        </div>

                        <div style={{ width: '100%' }}>
                            <input
                                onChange={(event) => {
                                    setSearchTerm(event.target.value)
                                }}
                                placeholder="Pesquisar por nome ou e-mail"
                                className="form-control"
                            />
                            <DataTable
                                columns={columns}

                                noDataComponent="Não há dados para mostrar"
                                data={handleFilter(users, selectedFilter, "dueDate", searchTerm)}
                                pagination={true}
                                paginationComponentOptions={paginationComponentOptions}
                            />
                        </div>
                    </div>

                    <Modal
                        open={modalEditVisible}
                        onClose={() => {
                            setModalEditVisible(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style_edition_modal}>
                            <form id="userForm" onSubmit={() => {}}>
                                
                                <div className="row d-flex justify-content-center">
                                    <span className="text-uppercase mx-4 text-center"><b>Editar perfil</b>  </span>
                                    <div className="col-md-10 col-sm-12 my-2">

                                        <input
                                            type="file"
                                            id="file"
                                            className="input"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={(event) => {
                                            handleFileChange(event)
                                            setImage(event)
                                            }}
                                        />

                                        <div className="upload-photo">
                                            <label for="file" id="label-file">
                                            <AddCircleSharp />
                                            <p>Foto de perfil</p>
                                            </label>
                                        </div>
                                        
                                        <label>Conta</label>
                                        <input
                                            disabled
                                            type="text"
                                            className="form-control"
                                            placeholder="admin"
                                            style={{marginBottom: '3vh'}}
                                        />   
                                        
                                        <label>Nome de exibição</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            style={{marginBottom: '3vh'}}
                                        />  
                                        
                                        <label>Primeiro Nome</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            style={{marginBottom: '3vh'}}
                                        />  


                                        <label>Último Nome</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            style={{marginBottom: '3vh'}}
                                        />

                                        <label>Contato</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            style={{marginBottom: '3vh'}}
                                        />

                                        <label>Celular</label>
                                        <InputMask
                                            type="text"
                                            name="tel"
                                            mask="(99) 99999-9999"
                                            placeholder="(xx) xxxxx-xxxx"
                                            className="form-control"
                                            required
                                            style={{marginBottom: '3vh'}}
                                            
                                        />

                                        <label>Endereço</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            style={{marginBottom: '3vh'}}
                                        />

                                        <label>Email</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => validateEmail(e)}
                                            style={{marginBottom: '3vh'}}
                                        />
                                        <span style={{fontWeight: 'bold', color: 'red',}}>{emailError}</span>

                                        <label>Senha</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            style={{marginBottom: '3vh'}}
                                        />
                                        
                                        <label>Confirmar senha</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 my-2  d-flex justify-content-center">
                                    <button type="submit" className="btn btn-success mx-4" style={{ height: '40px' }}> Salvar </button>
                                </div>
                            </form>
                        </Box>
                    </Modal>

                    <Modal
                        open={modalConfigVisible}
                        onClose={() => {
                            setModalConfigVisible(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style_edition_modal}>
                            <form id="userForm" onSubmit={() => {}}>
                                
                                <div className="row d-flex justify-content-center">
                                    <span className="text-uppercase mx-4 text-center"><b>Configurações</b></span>
                                    <div className="col-md-10 col-sm-12 my-2">
                                        <p>Tela de configurações</p>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 my-2  d-flex justify-content-center">
                                    <button className="btn btn-success mx-4" style={{ height: '40px' }}> Salvar </button>
                                </div>
                            </form>
                        </Box>
                    </Modal>

                    <Modal
                        open={modalMessageVisible}
                        onClose={() => {
                            setModalMessageVisible(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style_edition_modal}>
                            <form id="userForm" onSubmit={() => {}}>
                                
                                <div className="row d-flex justify-content-center">
                                    <span className="text-uppercase mx-4 text-center"><b>Configurações</b></span>
                                    <div className="col-md-10 col-sm-12 my-2">
                                        <label for="to">Enviar para</label>
                                        <input 
                                            id="to"
                                            className="form-control mb-3"
                                        />

                                        <label for="message">Mensagem</label>
                                        <textarea
                                            className="form-control"
                                            id="message"    
                                        />
                                    </div>

                                </div>
                                <div className="col-md-12 col-sm-12 my-2  d-flex justify-content-center">
                                    <button className="btn btn-success mx-4" style={{ height: '40px' }}>Enviar</button>
                                </div>
                            </form>
                        </Box>
                    </Modal>
                    
                    <Modal
                        open={modalInfoVisible}
                        onClose={() => {
                            setInfo({})
                            setModalInfoVisible(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className="row my-2">
                                <div className="col">
                                    <span className="text-uppercase">Informações Gerais do usuário {info.name}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Nome</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Nome Completo"
                                        value={info.name}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>CPF</label>
                                    <InputMask
                                        id="cpf-field"
                                        placeholder="CPF"
                                        mask="999.999.999-99"
                                        className="contact form-control"
                                        value={info.cpfCnpj}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        value={info.email}
                                        disabled />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Telefone Fixo</label>
                                    <InputMask
                                        placeholder="Celular"
                                        mask="(99) 9999-9999"
                                        className="contact form-control"
                                        value={info.phone}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Celular</label>
                                    <InputMask
                                        placeholder="Celular"
                                        mask="(99) 99999-9999"
                                        className="contact form-control"
                                        value={info.mobilePhone}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Código Postal</label>
                                    <InputMask
                                        placeholder="CEP"
                                        mask="99999-999"
                                        className="contact form-control"
                                        value={info.postalCode}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Endereço</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Endereço"
                                        value={info.address}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Numero</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Nº"
                                        value={info.addressNumber}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Complemento</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Nº"
                                        value={info.complement}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Provincia</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Provincia"
                                        value={info.province}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 my-2">
                                    <label>Emails Adicionais</label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Provincia"
                                        value={info.additionalEmails}
                                        disabled
                                    />
                                </div>

                                {
                                    info.groups !== undefined ? (
                                        <div className="col-md-3 col-sm-12 my-2">
                                            <label>Grupos</label>
                                            <select className="form-control">
                                                {
                                                    info.groups.map(grupo => (
                                                        <option key={grupo.id} value={grupo.id}>{grupo.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    ) : (
                                        <div className="col-md-3 col-sm-12 my-2">
                                            <label>Grupos</label>
                                            <select className="form-control" disabled>
                                                <option value={null}>Não há grupo cadastrado</option>
                                            </select>
                                        </div>
                                    )
                                }

                            </div>
                            <div className="col-md-6 col-sm-12 my-2">
                                <button className="btn btn-warning" style={{ height: '40px' }} onClick={() => {
                                    setModalInfoVisible(false);
                                    setInfo({})
                                }}> Fechar </button>
                            </div>
                        </Box>
                    </Modal>
                </div>
            </ResponsiveDrawer>
        </div>
    )
}
