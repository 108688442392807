import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import dayjs from 'dayjs';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import './styles.css';
import { Message } from '../../../api';

export default function MessageCard (props){
    const alert = useAlert();

    const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [newTextMessage, setNewTextMessage] = useState("");
    const [data, setData] = useState();
    const [isEdited, setIsEdited] = useState(false);

    const style_edition = {
        maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 4,
        p: 4,
        overflowY: 'auto'
    };

    const editMessage = async () => {
        let form = {
            "id": props?.messageId,
            "content": newTextMessage
        }

        let response = await Message.update(form);

        if(response.status !== "error") {
            alert.success("Mensagem editada");
            setTimeout(() => {
                props?.getMessages();
            }, 900)
            setModalEditVisible(false);
        }
    }

    const deleteMessage = async () => {
        let response = await Message.delete(props?.messageId);;

        if(response.status !== 'error'){
            alert.success("Mensagem excluída com sucesso");
            setTimeout(() => {
                props?.getMessages();
            }, 900)
            setModalDeleteVisible(false);
        }
    }

    function checkEdited() {
        let dateCreated = dayjs(props?.dataCreated);
        let dateEdited = dayjs(props?.dataModified);

        setData(dayjs(dateEdited).format("DD/MM/YYYY HH:mm:ss"));
        setIsEdited(!dateCreated.isSame(dateEdited));
    }

    useEffect(() => {
        checkEdited();
    },[]);
    
    return(
        <div className="message">
            <Modal
                open={modalDeleteVisible}
                onClose={() => setModalDeleteVisible(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style_edition}>
                    <div className="row my-2">
                        <div className="col">
                            <span className="text-uppercase">Tem certeza que deseja excluir essa mensagem?</span>
                        </div>
                    </div>
                    <button className="btn btn-success btn-sm" onClick={deleteMessage}>Sim</button>
                    <button className="btn btn-warning mx-2 btn-sm" onClick={() => setModalDeleteVisible(false)}>Voltar</button>
                </Box>
            </Modal>

            <Modal
                open={modalEditVisible}
                onClose={() => setModalEditVisible(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style_edition}>
                    <div className="row my-2">
                        <div className="col">
                            <textarea
                                className="w-100 rounded"
                                defaultValue={props?.text}
                                onChange={(event) => setNewTextMessage(event.target.value)}
                            />
                        </div>
                    </div>
                    <button className="btn btn-success btn-sm" onClick={editMessage}>Editar</button>
                    <button className="btn btn-warning mx-2 btn-sm" onClick={() => setModalEditVisible(false)}>Voltar</button>
                </Box>
            </Modal>

            <div className='d-flex container-actions-message-buttons'>
                <button className="bg-transparent border-0 edit-message" onClick={() => setModalEditVisible(true)}><Edit/></button>
                <button className="bg-transparent border-0 delete-message" onClick={() => setModalDeleteVisible(true)}><Delete/></button>
            </div>

            <img src={props?.pictureUrl} alt="" className="picture-message"/>
            <p><b>{props?.nameSent}</b></p>
            <p className='mt-3'>{props?.text}</p>
            <small>{ data }<small> {isEdited && "(editada)"}</small></small>
        </div>
    )
}