import API from './config';

const AsaasPaymentPending = {
  get: async(params) => {
    try {
      let response = await API().post(`/asaasPayment/paymentsPendingInstallments`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data.message || "Request error." };
    }
  }
}

export default AsaasPaymentPending;
