import { ResponsiveDrawer } from "../../components";
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { LineWave } from 'react-loader-spinner';

import './style.css';
import { AsaasPaymentPending } from "../../api";

export default function BankSlip() {
    const [asaasPayment, setAsaasPayment] = useState()
    const [loading, setLoading] = useState(true);

    function translateStatus(status) {
        if(status === "PENDING") {
            return "PENDENTE";
        } else if(status === "RECEIVED") {
            return "RECEBIDO";
        } else if(status === "CONFIRMED") {
            return "CONFIRMADO";
        } else if(status === "OVERDUE") {
            return "ATRASADO"; 
        }
    }

    const columns = [
        {
            name: 'Próximo vencimento',
            selector: row => dayjs(row.dueDate).format("DD-MM-YYYY"),
            sortable: true,
            width: "11vw",    
        },
        {
            name: 'Descrição',
            selector: row => <abbr title={row.description} >{row.description}</abbr>,
            sortable: true,
            width: "25vw",   
        },
        {
            name: 'Boleto',
            selector: row => (
              <a href={row.bankSlipUrl} target="_blank">
                <button className="button btn">
                  Acessar
                </button>
              </a>
            ),
            width: "10vw",
        },
        /*
        {
            name: 'Tipo',
            selector: row => row.billingType,
            sortable: true,
            width: "10vw"
        },*/
        {
            name: 'Status',
            selector: row => <p>{translateStatus(row.status)}</p>,
            sortable: true,
            width: "10vw",
        }, 
        {
            name: 'Detalhes',
            selector: row => (
               <a href={row.invoiceUrl} target="_blank">
                 <button className="button btn">
                   Acessar
                 </button>
               </a>
            ),
            width: "10vw"
        }
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
    };

    const getAsaasPaymentPayment = async () => {
        let response = await AsaasPaymentPending.get();

        if(response.status !== 'error') {
            setAsaasPayment(response);
            setLoading(false);
        }
    }

    useEffect(() => {
        getAsaasPaymentPayment();
    }, []);

    return (
        <div>
            <ResponsiveDrawer>
                <div className="row">
                    <div className="col">
                       <div className="card px-4 d-flex align-items-center">
                        {
                            loading ? (
                                <LineWave
                                    color="blue"
                                    height={110}
                                    width={110}
                                    ariaLabel="three-circles-rotating"
                                />
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={asaasPayment}
                                    noDataComponent="Não há dados para mostrar"
                                    pagination={true}
                                    paginationComponentOptions={paginationComponentOptions}
                                />
                            )
                        }
                       </div>
                    </div>
                </div>
            </ResponsiveDrawer>
        </div>
    )
}
