import React from 'react';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonAsaas = (props) => {

    return(
        <button className={`btn text-white ${props.bgColor} mx-2`} title={props.title} onClick={props.onclick}>
            <FontAwesomeIcon icon={faPowerOff} />
        </button>
    )
}

export default ButtonAsaas;