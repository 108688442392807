import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ListItemButton from '@mui/material/ListItemButton';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MyLocation from '@mui/icons-material/MyLocation';
import Assessment from '@mui/icons-material/Assessment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Dashboard from '@mui/icons-material/Dashboard';
import FmdBad from '@mui/icons-material/FmdBad';
import SettingsApplications from '@mui/icons-material/SettingsApplications';
import Email from '@mui/icons-material/Email';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import Devices from '@mui/icons-material/Devices';
import SubtitlesIcon from '@mui/icons-material/Subtitles';

import LogoMP from "../assets/img/logo.png";

import NavItem from './Navitem';

import { useDispatch } from "react-redux";
import { setToken } from '../redux/actions/user'
import { useAlert } from 'react-alert';
import { store } from '../redux/store';

import '../styles/navBar.css';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const typeUser = store.getState().user.data.roles[0];
  
  const { children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useDispatch()
  const alert = useAlert()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const Logout = () => {
    dispatch(setToken(null));
    alert.success("Logout Solicitado")
    setTimeout(function () {
      window.location.assign('/login')
    }, 2000)
  }
  const drawer = (
    <div className="navBar">
      <div className='text-center py-4'>
        <img src={LogoMP} alt="" />
      </div>
      <Divider />
      <List>
        {
          typeUser === "ROLE_DISTRIBUTOR" && (
          <NavItem href={"/home"} text="Dashboard">
            <Dashboard className="icon-navbar"/>
          </NavItem>)
        }
        {
          ((typeUser === "ROLE_DISTRIBUTOR" || typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER" || typeUser === "ROLE_MONITOR_ONLY") && process.env.REACT_APP_IS_DEV) && (
          <NavItem href={"/monitor"} text="Monitor">
            <MyLocation className="icon-navbar"/>
          </NavItem>)
        }
        {
          ((typeUser === "ROLE_DISTRIBUTOR" || typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER") && process.env.REACT_APP_IS_DEV) && (
          <NavItem href={"/reports"} text="Relatórios">
            <Assessment className="icon-navbar"/>
          </NavItem>)
        }
        {
          ((typeUser === "ROLE_DISTRIBUTOR" || typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER") && process.env.REACT_APP_IS_DEV) && (
          <NavItem href={"/alerts"} text="Alertas">
            <FmdBad className="icon-navbar"/>
          </NavItem>)
        }
        {
          typeUser === "ROLE_DISTRIBUTOR" && (
          <NavItem href={"/users"} text="Lista de Usuários">
            <PersonIcon className="icon-navbar"/>
          </NavItem>)
        }
        {
          typeUser === "ROLE_DISTRIBUTOR" && (
          <NavItem href={"/asaas"} text="Usuários AsaaS">
            <PersonIcon className="icon-navbar"/>
          </NavItem>)
        }
        {
          typeUser === "ROLE_DISTRIBUTOR" && (
          <NavItem href={"/notifications"} text="Gerenciar Notificações">
            <NotificationsIcon className="icon-navbar"/>
          </NavItem>)
        }
        {
          ((typeUser === "ROLE_DISTRIBUTOR" || typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER" || typeUser === "ROLE_MONITOR_ONLY") && process.env.REACT_APP_IS_DEV) && (
          <NavItem href={"/profile"} text="Perfil">
            <AccountCircle className="icon-navbar"/>
          </NavItem>)
        }
        {
          (typeUser === "ROLE_DISTRIBUTOR" && process.env.REACT_APP_IS_DEV) && (
          <NavItem href={"/configurations"} text="Configurações">
            <SettingsApplications className="icon-navbar"/>
          </NavItem>)
        }
        {
          ((typeUser === "ROLE_DISTRIBUTOR" || typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER") && process.env.REACT_APP_IS_DEV) && (
          <NavItem href={"/messages"} text="Mensagens">
            <Email className="icon-navbar"/>
          </NavItem>)
        }
        {
          (typeUser === "ROLE_DISTRIBUTOR" && process.env.REACT_APP_IS_DEV) && (
          <NavItem href={"/manager"} text="Painel Administrativo">
            <ManageAccounts className="icon-navbar"/>
          </NavItem>)
        }
        {
          (typeUser === "ROLE_DISTRIBUTOR" && process.env.REACT_APP_IS_DEV) && ( 
          <NavItem href={"/devices"} text="Dispositivos">
            <Devices className="icon-navbar"/>
          </NavItem>)
        }
        {
          typeUser === "ROLE_DISTRIBUTOR" && (
          <NavItem href={"/contas"} text="Carnês">
            <SubtitlesIcon className="icon-navbar"/>
          </NavItem>
          )
        }
      </List>
      <Divider />
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar className="logout-bar">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <nav aria-label="main mailbox folders">
            <List className='d-flex'>
              <ListItem disablePadding>
                <ListItemButton onClick={Logout}>
                  <ListItemIcon>
                    <LogoutIcon className="icon-navbar"/>
                  </ListItemIcon>
                  <ListItemText primary="Sair" />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;