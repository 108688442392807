import API from './config';

const Device = {
  create: async(params) => {
    try {
      let response = await API().post(`/device/createDevice`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data || "Request error." };
    }
  },
  getAll: async() => {
    try {
      let response = await API().get(`/device/findAll`);
        
      return await response;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getById: async(id) => {
    try {
      let response = await API().get(`/device/findById/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByImei: async(imei) => {
    try {
      let response = await API().get(`/device/findByImei/${imei}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByUser: async(userId) => {
    try {
      let response = await API().get(`/device/findByUser/${userId}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  update: async(params) => {
    try {
      let response = await API().put(`/device/updateDevice/`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error."};
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/device/delete/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
}

export default Device;
