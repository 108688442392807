import dayjs from "dayjs";
import * as COLUMNS from './TableColumns';
import * as CARDS_TITLES from './CardsTitlesReports';

const COLORS = {
  LIGHT_BLUE: 'rgba(75, 192, 192, 0.7)',
  BLUE: 'rgba(54, 162, 235, 0.7)',
  PINK: 'rgba(255, 99, 132, 0.7)'
}

/* 
 * @param date: string
 * @param operation: enum["previous", "current"]
 * @param type: enum["days", "week", "month", "year"]
 * @return boolean
 */
const diffDate = (date, operation, type) => {
  if (type === "days")
    return dayjs(date).startOf(type).isSame(dayjs().subtract(1, type).startOf(type));
  // check if date is between fisrt and last day of previous week/month/year
  if (operation === "previous")
    return dayjs(date).isBetween(dayjs().subtract(1, type).startOf(type), dayjs().subtract(1, type).endOf(type), null, "()");
  // check if date is between fisrt and last day of current week/month/year
  if (operation === "current")
    return dayjs(date).isBetween(dayjs().startOf(type), dayjs().endOf(type), null, "()");
}

/* 
 * @param originalArray: array
 * @param filterOptions: object (components/filter)
 * @param key: string
 * @param search: string
 * @return array
 */

const handleFilter = (originalArray, filterOptions, key, search) => {
  return originalArray.filter((item) => {
    if (filterOptions.value?.length > 0) {
      // if there is a filter selected, check if date and searched string matches
      if (diffDate(item[key], filterOptions.value[0], filterOptions.value[1])) {
        return item.name.toLowerCase().includes(search.toLowerCase()) && item;
      }
    } else {
      // if there is no filter selected, return same item if it matches searched string
      return item.name.toLowerCase().includes(search.toLowerCase()) && item;
    }

    return false;
  })
}

export {
  COLORS,
  COLUMNS,
  CARDS_TITLES,
  diffDate,
  handleFilter
}
