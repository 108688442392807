import API from './config';

const AlarmHistory = {
  create: async(params) => {
    try {
      let response = await API().post(`/alarm/create`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data.message || "Request error." };
    }
  },
  getAll: async() => {
    try {
      let response = await API().get(`/alarm/findAll`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getById: async(id) => {
    try {
      let response = await API().get(`/alarm/findById/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByDevice: async(deviceId) => {
    try {
      let response = await API().get(`/alarm/findByDevice/${deviceId}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByTrackHistory: async(trackingId) => {
    try {
      let response = await API().get(`/alarm/findByTrackingHistory/${trackingId}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  update: async(params) => {
    try {
      let response = await API().put(`/alarm/update`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error."};
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/alarm/delete/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
}

export default AlarmHistory;
