import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useStore } from 'react-redux';
import dayjs from 'dayjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Add from '@mui/icons-material/Add';

import Device from '../../../api/Devices';

export default function ModalAdicionar(props){

  const alert = useAlert();
  const store = useStore();
  
  const [selectFile, setSelectFile] = useState(null);
  const [image, setImage] = useState(null);
  const [model, setModel] = useState("");
  const [typeDevice, setTypeDevice] = useState("Motocicleta");
  const [imeiId, setImeiId] = useState("");
  const [vehiclePartNumberDevice, setVehiclePartNumberDevice] = useState("");
  const [remarks, setRemarks] = useState("");

  const [urlProfile, setUrlprofile] = useState("");
  const [profile, setProfile] = useState("");

  const style_edition = {
    minWidth: '50vw',
    maxHeight: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto'
  };


  let userData = store.getState().user.data;

  let date = new  Date();
  date.setMonth(date.getMonth() + 1);

  let exFactoryDate = dayjs(date).format("YYYY-MM-DD"); 

  const doUpload = (event) => {
    let formData = new FormData();
    formData.append("image", event);
 
    fetch('https://api.imgur.com/3/image', {
      method: 'POST',
      headers: {
        Authorization: "Client-ID a2191559833e11f"
      },
      body: formData,
      redirect: 'follow'
    }).then(data => data.json()).then((result) => {
      setUrlprofile(result.data.link)
    })
  }

  const addNewDevice = async () => {
    let form = {
      "activatedDate": dayjs().format("YYYY-MM-DD"),
      "exFactoryDate": exFactoryDate,
      "icon": urlProfile || "https://www.iconpacks.net/icons/1/free-car-icon-1057-thumb.png",
      "imeiId": imeiId,
      "model": model,
      "platformExpirationDate": "2022-04-16",
      "remarks": remarks,
      "sellDate": "2022-03-16",
      "speedingValue": 0,
      "targetUser": {
        "id": userData.id
      },
      "type": typeDevice,
      "vehiclePartNumber": vehiclePartNumberDevice
    }

    let response = await Device.create(form);

    if(response.status === 'error'){
      alert.error(response?.message?.icon || "Não foi possível cadastrar o dispositivo");
    }else{
      alert.success("Dispositivo cadastrado com succeso");
      window.location.reload();
    }
  }

  return(
    <>
    <Modal
      open={props.visible}
      onClose={() => props.setModalVisible(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style_edition}>
        <div className="row my-2">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-12">
                <h4 className="m-0">Adicionar dispositivo</h4>
              </div>
            </div>
          </div>

          <div className="card-body">
            <form id="userForm" onSubmit={() => {}}>
              <div className="row">
                <div>
                  <input
                    type="file"
                    id="file"
                    className="input"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                     doUpload(event.target.value)
                     console.log(event.target.value)
                    }}
                  />

                  {
                    urlProfile === "" ? (
                      <div className="upload-photo">
                        <label for="file" id="label-file">
                        <Add />
                        <p>Ícone</p>
                        </label>
                      </div>
                    ) : (
                      <div className="profile-photo">
                        <label for="file" id="reupload-file">
                          <img src={profile} alt="" className="reupload-photo-img" />
                        </label>
                      </div>
                    )
                  }
                  
                  <label>Modelo do veículo</label>
                  <input
                    required
                    type="text"
                    className="form-control mb-4"
                    onChange={(event) => setModel(event.target.value)}
                  />

                  <label>Tipo</label>
                  <select className="form-control mb-4" value={typeDevice} onChange={(event) => setTypeDevice(event.target.value)}>
                    <option name="motocicleta" value="Motocicleta">Motocicleta</option>
                    <option name="motocicleta" value="Carro">Carro</option>
                  </select>

                  <label>IMEI</label>
                  <input
                    required
                    type="text"
                    className="form-control mb-4"
                    onChange={(event) => setImeiId(event.target.value)}
                  />

                  <label>Número de registro do veículo</label>
                  <input
                    required
                    type="text"
                    className="form-control mb-4"
                    onChange={(event) => setVehiclePartNumberDevice(event.target.value)}
                  />

                  <label>Observações</label>
                  <textarea
                    required
                    type="text"
                    className="form-control mb-4"
                    onChange={(event) => setRemarks(event.target.value)}
                  />
                  </div>
                </div>        

                <div className="col-md-6 col-sm-12 my-2">
                  <button type="button" className="btn btn-success btn-sm" onClick={() => {
                    addNewDevice();
                    props.setModalVisible(false);
                  }} > Adicionar </button>
                  <button className="btn btn-warning mx-2 button-device btn-sm" onClick={() => props.setModalVisible(false)}> Fechar </button>
                </div>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
    </>
  )
}
