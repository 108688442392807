import ResponsiveDrawer from "../../components/ResponsiveDrawer";
import Button from './Button/index';

import { Device, DeviceGeoFence } from '../../api'
import TrackingHistory from '../../api/TrackingHistory';

import { store } from '../../redux/store';

import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';

import { useAlert } from 'react-alert';

import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import Draggable from 'react-draggable'; 
import * as L from "leaflet"; 
import 'leaflet-measure'; 
import 'leaflet-measure/dist/leaflet-measure.css';

import SateliteImgExample from '../../assets/img/satellite.png';
import TerrainImgExample from '../../assets/img/terrain.png';
import RoadmapImgExample from '../../assets/img/roadmap.png';
import HibridImgExample from '../../assets/img/hibrid-map.png';
import OsmStandartImgExample from '../../assets/img/osm-standart.png';
import Osm1ImgExample from '../../assets/img/osm-1.png';
import Osm2ImgExample from '../../assets/img/osm-2.png';

import carIcon from '../../assets/img/car.svg';
import motoIcon from '../../assets/img/moto.svg';
import FenceRounded from '@mui/icons-material/FenceRounded';

import './style.css';
import { Box, Modal } from "@mui/material";

export default function Monitor(){
    const alert = useAlert();

    const [devices, setDevices] = useState([]);
    const [trackings, setTrackings] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [geoFenceCoords, setGeoFenceCoords] = useState(null);
    const [modalGeoFenceVisible, setModalGeoFenceVisible] = useState(false);
    const [deviceSelectedGeoFenceId, setDeviceSelectedGeoFenceId] = useState(null);
    const [radius, setRadius] = useState(0);
    const [geoFenceName, setGeoFenceName] = useState("");
    const [typeMap, setTypeMap] = useState(1);

    const getDevices = async () => {
        let response = await Device.getByUser(store.getState().user.data.id)

        if(response.status !== 'error') {
            setDevices(response);
            let aux = [];
            for(let i = 0; i < response.length; i++) {
                let responseTracking = await TrackingHistory.getByDevice(response[i].id);
                if(responseTracking.status !== 'error') {
                    if(responseTracking.length > 0){
                        aux.push(responseTracking);
                    }
                }
            }
            if(aux.length > 0) {
                setTrackings(aux);
            }
            
        }else{
            alert.error(response.message)
        }
    }

    const createDeviceGeoFence = async (event) => {
        event.preventDefault();
        
        let imeiDevice = "";
        
        devices.forEach((item) => {
            if(item.id === deviceSelectedGeoFenceId) {
                imeiDevice = item.imeiId
            }
        });

        let form = {
            "alarmType": "ALARM_CIRCLE_IN",
            "device": {
              "id": deviceSelectedGeoFenceId,
            },
            "efenceName": geoFenceName,
            "imei": imeiDevice,
            "latitude": geoFenceCoords.lat,
            "longitude": geoFenceCoords.lng,
            radius
        }

        let response = await DeviceGeoFence.create(form);
        if(response.status !== 'error') {
            setModalGeoFenceVisible(false)
            alert.success("Cerca Eletrônica Definida")
        } else {
            alert.error(response.status)
        }
    }

    const addLeafletMeasureControl = (map) =>{
        let measureControl = new L.Control.Measure({
            position: 'topright',
            lineColor: 'blue',
            primaryAreaUnit: 'kilometers', 
            secondaryAreaUnit: 'meters',
            primaryLengthUnit: 'kilometers', 
            secondaryLengthUnit: 'meters',
            
        });
        measureControl.addTo(map);
    }
      
    function LocationMarker() {
        const map = useMapEvents({
          click: (location) =>  {
            setModalGeoFenceVisible(true);
            setGeoFenceCoords(location.latlng);
          }
        })
      
        return geoFenceCoords !== null && <Marker position={geoFenceCoords}></Marker>;
    }

    const style_edition = {
        maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    };  
  
    const position = [-9.579661, -35.760475];

    const iconCar = new L.Icon({
        iconUrl: carIcon,
        iconRetinaUrl: carIcon,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [55, 105],
        className: 'leaflet-div-icon'
    });

    const iconMoto = new L.Icon({
        iconUrl: motoIcon,
        iconRetinaUrl: motoIcon,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [55, 105],
        className: 'leaflet-div-icon'
    });

    useEffect(() => {
        getDevices();
    }, []);
  
    return (
        <div>
            <ResponsiveDrawer>
                <div className="row">
                    <Modal
                        open={modalGeoFenceVisible}
                        onClose={() => {
                        setModalGeoFenceVisible(false)
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style_edition}>
                            <div className="row my-2">
                                <div className="col">
                                    <span className="text-uppercase">Cerca Eletrônica</span>
                                </div>
                            </div>
                            <form onSubmit={createDeviceGeoFence}>
                                <label>Nome</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Ex: Região de São Paulo"
                                    onChange={(event) => setGeoFenceName(event.target.value)}
                                />

                                <label className="mt-4">Raio (Km)</label>
                                <input
                                    required
                                    type="number"
                                    className="form-control"
                                    placeholder="Defina o raio da cerca"
                                    min="1"
                                    step="0.1"
                                    onChange={(event) => setRadius(event.target.value)}
                                />

                                <label className="mt-4">Dispositivo</label>
                                <select className="form-control" onChange={(event) => setDeviceSelectedGeoFenceId(event.target.value)}> 
                                    <option value={""}>Selecione</option>
                                    {
                                        devices.map((item, index) => {
                                            return(
                                                <option key={index} value={item.id}>{item.model}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div className="col-md-6 col-sm-12 my-2">
                                    <button type="submit" className="btn btn-success" style={{ height: '40px' }}> Salvar </button>
                                    <button className="btn btn-warning mx-2" style={{ height: '40px' }} onClick={() => setModalGeoFenceVisible(false)}> Fechar </button>
                                </div>
                            </form>
                        
                        </Box>
                    </Modal>

                    <div className="col">
                        <Draggable
                            axis="x"
                            handle=".handle"
                            defaultPosition={{x: 0, y: 0}}
                            position={null}
                            grid={[25, 25]}
                            scale={1}
                            
                            >
                            <div style={{position: 'absolute', zIndex: '1'}} className="users-float-list">
                                <div className="handle">
                                    <div style={{ width: '100%', backgroundColor: 'blue', borderRadius: '.5rem .5rem 0 0', padding: '.5rem',}}>
                                        <b style={{color: '#FFFFFF'}}>Meus dispositivos</b>
                                    </div>
                                    
                                    <div style={{padding: '1rem'}}>
                                        <input type="text" placeholder="Pesquisar" id="input-filter-my-devices" onChange={(event) => setSearchTerm(event.target.value)}/>
                                        <ul style={{listStyle: 'none', marginLeft: '-2rem'}}>
                                            {
                                                //implementar filtro
                                                devices.map((item, index) => <li key={index}>{item.model}</li>)
                                            }
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </Draggable>               
                        <div className="card" style={{marginTop:'0vh'}}>
                            <div className="container-buttons-float">
                                <Button
                                    icon={<FenceRounded/>}
                                    name="Selecione no mapa o local que deseja centralizar a cerca"
                                />
                            </div>
                          
                            <MapContainer center={position} pos zoom={13} scrollWheelZoom={true} style={{zIndex: '0'}} whenCreated={(map) => addLeafletMeasureControl(map)} >                            
                                <LocationMarker/>
                               

                                {
                                    typeMap === 1 &&(
                                        <ReactLeafletGoogleLayer
                                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                            type={"satellite"}
                                        /> 
                                    )
                                }

                                {
                                    typeMap === 2 &&(
                                        <ReactLeafletGoogleLayer
                                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                            type={"hybrid"}
                                        /> 
                                    )
                                }

                                {
                                    typeMap === 3 &&(
                                        <ReactLeafletGoogleLayer
                                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                            type={"roadmap"}
                                        /> 
                                    )
                                }

                                {
                                    typeMap === 4 &&(
                                        <ReactLeafletGoogleLayer
                                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                            type={"terrain"}
                                        /> 
                                    )
                                }

                                {
                                    typeMap === 5 &&(
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />)
                                }
                                    
                                {
                                    typeMap === 6 && (
                                    <TileLayer
                                        attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}{r}.png"
                                    />)  
                                }
                                
                                {
                                    typeMap === 7 &&(
                                    <TileLayer
                                        attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                                    />)
                                }
                                    
                                {   
                                    trackings.length > 0 &&
                                    trackings.map((item, index) => (
                                      <Marker
                                        position={[item[0].endLocation.split(",")[0], item[0].endLocation.split(",")[1]]}
                                        icon={item[0].device.type === 'Carro' ? iconCar : iconMoto} opacity={100}
                                      >
                                         <Popup>
                                           <p>{item[0].device.model}</p>
                                           <p><b>Estado:</b>{item[0].status} ({item.speed})</p>
                                           <p><b>Coordenada:</b> {item[item.length-1].endLocation}</p>
                                         </Popup>
                                       </Marker>
                                     ))
                                }
                            </MapContainer>
                        </div>
                        <div className="d-flex  p-4">
                            <div className="container-choice-map">
                                <div>
                                    <label>Google - Satélite</label>
                                    <button onClick={() => setTypeMap(1)}><img src={SateliteImgExample} alt="Google Maps Satélite"/></button>
                                </div>

                                <div>
                                    <label>Google - Híbrido</label>
                                    <button onClick={() => setTypeMap(2)}><img src={HibridImgExample} alt="Google Maps Híbrido"/></button>
                                </div>

                                <div>
                                    <label>Google - Roadmap</label>
                                    <button onClick={() => setTypeMap(3)}><img src={RoadmapImgExample} alt="Google Maps Roadmap"/></button>
                                </div>
                                
                                <div>
                                    <label>Google - Terrain</label>
                                    <button onClick={() => setTypeMap(4)}><img src={TerrainImgExample} alt="Google Maps Terrain"/></button>
                                </div>

                                <div>
                                    <label>OSM - Padrão</label>
                                    <button onClick={() => setTypeMap(5)}><img src={OsmStandartImgExample} alt="OSM padrão"/></button>
                                </div>

                                <div>
                                    <label>OSM - 1</label>
                                    <button onClick={() => setTypeMap(6)}><img src={Osm1ImgExample} alt="OSM 1"/></button>
                                </div>

                                <div>
                                    <label>OSM - 2</label>
                                    <button onClick={() => setTypeMap(7)}><img src={Osm2ImgExample} alt="OSM 2"/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>              
            </ResponsiveDrawer>
        </div>
    )
}
