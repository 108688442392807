import { combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import UserReducers from '../reducers/user';

const combinedReducers = combineReducers({
  user: UserReducers
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
}

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
