import API from './config';

const DeviceGeoFence = {
  create: async(params) => {
    try {
      let response = await API().post(`/deviceGeofence`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data || "Request error." };
    }
  },
  getAll: async() => {
    try {
      let response = await API().get(`/deviceGeofence`);
        
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByAlarmType: async(alarmType) => {
    try {
      let response = await API().get(`/deviceGeofence/alarmType/${alarmType}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByIdDevice: async(alarmType) => {
    try {
      let response = await API().get(`/deviceGeofence/idDevice/${alarmType}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getById: async(id) => {
    try {
      let response = await API().get(`/deviceGeofence/idDeviceGeofence/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByImei: async(imei) => {
    try {
      let response = await API().get(`/deviceGeofence/imei/${imei}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  update: async(params) => {
    try {
      let response = await API().put(`/deviceGeofence`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error."};
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/deviceGeofence/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
}

export default DeviceGeoFence;
