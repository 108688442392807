import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from 'react-alert';
import { User } from "../../api";
import LogoMP from "../../assets/img/logo.png"; 

export default function RecoveryPassword() {
    const alert = useAlert();

    let params = useParams('/recoverPassword/token/:token')

    const [isValid, setIsValid] = useState(false);
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    async function validateToken() {
        if(params.token) {
            let response = await User.validateForgotToken(params.token);

            if(response.status !== 'error') {
                if(response.data === 'Token válido.') {
                    setIsValid(true);
                }
            }
        } 
    }

    async function handleUpdateForgotPassword(e) {
        e.preventDefault();

        let response = await User.updateForgotPassword(password, params.token);

        if (response.status !== 'error') {
            alert.success("Senha alterada com sucesso.");
            window.location.assign("/login");
        }
    }

    function handlePassword(e) {
        setPassword(e.target.value);
    }

    function handleConfirmPassword(e) {
        setConfirmPassword(e.target.value);
    }
    
    useEffect(() => {
        validateToken();
    },[]);

    return(
    <div className="container-login">
        <div className="flex-row align-items-center ">
            <div className="container">
            <div className="row justify-content-center">
                <div className="card card-login">
                <div className="card-body card-content-login">
                    <form>
                        <div className="text-center" id="img-logo-login">
                            <img src={LogoMP} alt="Logo MP Rastreamento"/>
                        </div>
                        <div className="form-container">

                            {
                                isValid && (
                                    <form>
                                        <div className="input-group mt-3 mb-2">
                                            <span className="input-group-text rounded-left"><FontAwesomeIcon icon={faLock} /></span>
                                            <input type="password" className="form-control rounded-right" placeholder="Senha" onChange={handlePassword}/>
                                        </div>

                                        <div className="input-group mt-3 mb-2">
                                            <span className="input-group-text rounded-left"><FontAwesomeIcon icon={faLock} /></span>
                                            <input type="password" className="form-control rounded-right" placeholder="Confirmar Senha" onChange={handleConfirmPassword}/>
                                        </div>

                                        {
                                            password !== confirmPassword && (
                                                <p className="text-danger">Senhas não conferem.</p>
                                            )
                                        }

                                        <div className="my-3" >
                                            <button type="button" className="border-0 py-2 px-4 rounded button" onClick={handleUpdateForgotPassword}>Alterar</button>
                                        </div>
                                    </form>
                                )
                            }
                        </div>
                    </form>
                </div>
                <div className="card-content-login">
                    <p className="d-none">RASTREAR, PROTEGER, POUPAR!</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    )
}