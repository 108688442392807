import API from './config';

const Notifications = {
  getAll: async() => {
    try {
      let response = await API().get(`/notifications/findAll`); 
      return await response;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  update: async(params) => {
    try {
      let response = await API().put(`/notifications/update`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
}

export default Notifications;