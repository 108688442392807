import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useAlert } from 'react-alert';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import DataTable from 'react-data-table-component';
import { Bar } from 'react-chartjs-2';

import { COLUMNS, handleFilter } from "../../utils";
import AssaSAPI from "../../api/AssaS";
import { Filter, ResponsiveDrawer } from "../../components";

import '../../styles/global.css';
import './styles.css';

dayjs.extend(isBetween);
const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
};

const style = {
  maxWidth: '100vw',
  maxHeight: '100%',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto'
};

const styleDefaulters = {
  maxWidth: '100vw',
  maxHeight: '100%',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto'
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Usuários Totais',
    },
  },
};

const Home = () => {
  const alert = useAlert()

  const [total, setTotal] = useState([]);
  const [users, setUsers] = useState([]);
  const [inactives, setInactives] = useState([]);
  const [inactivesUsers, setInactivesUsers] = useState([]);
  const [selectedFilterInadimplents, setSelectedFilterInadimplents] = useState("");
  const [selectedFilterInactive, setSelectedFilterInactive] = useState([]);
  const [selectedFilterNotification, setSelectedFilterNotification] = useState({});
  const [usersIrregular, setUsersIrregular] = useState([])
  const [arrayIrregularsUsers, setArrayIrregularsUsers] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [modalVisibleDefaulters, setModalVisibleDefaulters] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermIrregulars, setSearchTermIrregulars] = useState("");
  const [searchTermInactive, setSearchTermInactive] = useState("");

  const DATASET_TOTAL = {
    label: 'Usuários Totais',
    data: [total],
    backgroundColor: '#024AE6'
  }

  const DATASET_ACTIVE = {
    label: 'Usuários Ativos',
    data: [users],
    backgroundColor: '#E6D119'
  }

  const DATASET_INACTIVE = {
    label: 'Usuários Inativos',
    data: [inactives],
    backgroundColor: '#011746',
  }

  const DATASET_DEFAULTERS = {
    label: 'Usuários Inadimplentes',
    data: [usersIrregular],
    backgroundColor: '#E6D119'
  }

  const loadNotifications = async () => {
    let response = await AssaSAPI.getLastNotifiedClients();
    if (response.status !== 'error') {
      setNotifications(response)
    } else {
      alert.error("Erro, verifique as informações de acesso")
    }
  }

  const loadUsers = async () => {
    let response = await AssaSAPI.getInactiveClients();
    if (response.status !== 'error') {
      setUsers(response.count_active);
      setInactives(response.count_inactive)
      setInactivesUsers(response.inactive_users)
      setTotal(response.count_total);
    } else {
      alert.error("Erro, verifique as informações de acesso")
    }
  }

  const loadIrregulars = async () => {
    let response = await AssaSAPI.getOverdueClients();
    if (response.status !== 'error') {
      setUsersIrregular(response.count_irregular);
      setArrayIrregularsUsers(response.irregular_users)
    } else {
      alert.error("Erro, verifique as informações de acesso")
    }
  }

  useEffect(() => {
    loadUsers();
    loadIrregulars();
    loadNotifications();
  }, [])

  return (
    <div>
      <ResponsiveDrawer>
        <div className="row">
          <div className="col-md-mb-3">
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h4 className="m-0">Dados gerais</h4>
                  </div>
                </div>
              </div>
              <div className="card-body d-flex justify-content-center">
                <div>
                  <Bar
                    data={{
                      labels: [""],
                      datasets: [
                        { ...DATASET_TOTAL },
                        { ...DATASET_ACTIVE },
                        { ...DATASET_INACTIVE },
                      ]
                    }}
                    options={options}
                    style={{ width: '400px' }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h4 className="m-0">Usuários Inativos</h4>
                  </div>
                  <div className="col-6">
                    <div className="text-end">
                      <button className="btn btn-primary button" onClick={() => setModalVisible(true)}>
                        Ver lista de usuários inativos
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body d-flex justify-content-center">
                <div className="w-100">
                  <Bar
                    options={options}
                    data={{
                      labels: [""],
                      datasets: [
                        { ...DATASET_TOTAL },
                        { ...DATASET_INACTIVE }
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h4 className="m-0">Usuários Inadimplentes</h4>
                  </div>
                  <div className="col-6">
                    <div className="text-end">
                      <button className="btn btn-primary button" onClick={() => setModalVisibleDefaulters(true)}>
                        Ver usuários Inadimplentes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body d-flex justify-content-center">
                <div className="w-100">
                  <Bar
                    options={options}
                    data={{
                      labels: [""],
                      datasets: [
                        { ...DATASET_TOTAL },
                        { ...DATASET_DEFAULTERS }
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h4 className="m-0">Últimas notificações enviadas</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className='container-filter-reports'>
                  <p><b>Filtros</b></p>
                  <Filter onClick={(item) => setSelectedFilterNotification(item)} />
                </div>
                <div className="w-100">
                  <input
                    onChange={(event) => setSearchTerm(event.target.value)}
                    placeholder="Pesquisar por nome"
                    className="form-control"
                  />
                  <DataTable
                    columns={[
                      { ...COLUMNS.NAME },
                      { ...COLUMNS.EMAIL },
                      { ...COLUMNS.NOTIFICATION },
                      { ...COLUMNS.NOTIFICATION_DATE },
                    ]}
                    noDataComponent="Não há dados para mostrar"
                    data={handleFilter(notifications, selectedFilterNotification, "notificationSentDay", searchTerm)}
                    pagination={true}
                    paginationComponentOptions={paginationComponentOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Modal
            open={modalVisible}
            onClose={() => setModalVisible(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="row pt-3">
                <div className="col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <h4 className="m-0">Inativos</h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className='container-filter-reports'>
                        <p><b>Filtros</b></p>
                        <Filter onClick={setSelectedFilterInactive} />
                      </div>
                      <div className="w-100">
                        <input
                          onChange={(event) => setSearchTermInactive(event.target.value)}
                          placeholder="Pesquisar por nome"
                          className="form-control"
                        />
                        <DataTable
                          columns={[
                            { ...COLUMNS.NAME },
                            { ...COLUMNS.EMAIL },
                          ]}
                          noDataComponent="Não há dados para mostrar"
                          data={handleFilter(inactivesUsers, selectedFilterInactive, "dueDate", searchTermInactive)}
                          pagination={true}
                          paginationComponentOptions={paginationComponentOptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>

        <div>
          <Modal
            open={modalVisibleDefaulters}
            onClose={() => setModalVisibleDefaulters(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleDefaulters}>
              <div className="row pt-3">
                <div className="col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <h4 sclassName="m-0">Inadimplentes</h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className='container-filter-reports'>
                        <p><b>Filtros</b></p>
                        <Filter onClick={setSelectedFilterInadimplents} />
                      </div>
                      <div className="w-100">
                        <input
                          onChange={(event) => setSearchTermIrregulars(event.target.value)}
                          placeholder="Pesquisar por nome"
                          className="form-control"
                        />
                        <DataTable
                          columns={[
                            { ...COLUMNS.NAME },
                            { ...COLUMNS.EMAIL },
                            { ...COLUMNS.DUE_DATE },
                            { ...COLUMNS.DUE_DAYS },
                          ]}
                          noDataComponent="Não há dados para mostrar"
                          data={handleFilter(arrayIrregularsUsers, selectedFilterInadimplents, "dueDate", searchTermIrregulars)}
                          pagination={true}
                          paginationComponentOptions={paginationComponentOptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </ResponsiveDrawer>
    </div>
  )
}

export default Home;
