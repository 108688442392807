import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { Bar } from 'react-chartjs-2';
import { useAlert } from 'react-alert';

import { Filter, ResponsiveDrawer } from "../../components";

import { COLUMNS, handleFilter } from "../../utils/index.js";

import 'chart.js/auto';

import './style.css';
import AssaSAPI from '../../api/AssaS';
import Device from '../../api/Devices';
import { AlarmHistory } from '../../api';

export default function Panel(){
    const alert = useAlert();

    const [total, setTotal] = useState([]);
    const [users, setUsers] = useState([]);
    const [inactives, setInactives] = useState([]);
    const [selectedFilterNotification, setSelectedFilterNotification] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [notifications, setNotifications] = useState([]);
    const [devices, setDevices] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("");
    const [alarms, setAlarms] = useState([]);

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Usuários',
          },
        },
    };

    const columnsDevices = [
        {
          name: 'Modelo',
          selector: row => row.model,
          sortable: true
        },
        {
          name: 'Tipo',
          selector: row => row.type,
          sortable: true
        },
        {
          name: 'Data de ativação',
          selector: row => row.activatedDate,
          sortable: true
        },
        {
          name: 'Data de expiração',
          selector: row => row.platformExpirationDate,
          sortable: true
        },
        {
          name: 'IMEI',
          selector: row => row.imeiId,
          sortable: true
        },
        {
          name: 'Velocidade',
          selector: row => row.speedingValue,
          sortable: true
        },
        {
          name: 'Observações',
          selector: row => row.remarks,
          sortable: true
        },
        {
          name: 'Data de venda',
          selector: row => row.sellDate,
          sortable: true
        } 
    ];

    const columns = [
        {
            name: 'Hora do alarme',
            selector: row => row.created,
            sortable: true
        },
        {
            name: 'Tipo de alarme',
            selector: row => row.alarmType,
            sortable: true
        },
        {
            name: 'Dispositivo',
            selector: row => row.device.model,
            sortable: true
        },
        {
            name: 'Descrição',
            selector: row => row.details,
            sortable: true
        },
    ];

    const DATASET_TOTAL = {
        label: 'Usuários Totais',
        data: [total],
        backgroundColor: '#024AE6'
    }

    const DATASET_ACTIVE = {
        label: 'Usuários Ativos',
        data: [users],
        backgroundColor: '#E6D119'
    }

    const DATASET_INACTIVE = {
        label: 'Usuários Inativos',
        data: [inactives],
        backgroundColor: '#011746',
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
    };

    const loadUsers = async () => {
        let response = await AssaSAPI.getInactiveClients();
        if (response.status !== 'error') {
          setUsers(response.count_active);
          setInactives(response.count_inactive)
          setTotal(response.count_total);
        } else {
          alert.error("Erro, verifique as informações de acesso")
        }
    }

    const loadNotifications = async () => {
        let response = await AssaSAPI.getLastNotifiedClients();
        if (response.status !== 'error') {
          setNotifications(response)
        } else {
          alert.error("Erro, verifique as informações de acesso")
        }
    }

    const getDevices = async () => {
        let response = await Device.getAll();
        setDevices(response.data);
    }

    const getAlarms = async () => {
        let response = await AlarmHistory.getAll();
        
        if(response.status !== 'error') {
            setAlarms(response);
        }
    }

    useEffect(() => {
        loadUsers();
        loadNotifications();
        getDevices();
        getAlarms();
    }, []);

    return (
        <div>
            <ResponsiveDrawer>
                <div className="row d-flex">
                    <div className="col-md-mb-3">
                        <div className="card">
                            <div className="card-header">
                                <div className="row align-items-center">
                                <div className="d-flex justify-content-between">
                                    <h4 className="m-0">Dados gerais</h4>
                                    <a href="/asaas"><button className="btn btn-primary button">Gerenciar</button></a>
                                </div>
                                </div>
                            </div>
                            <div className="card-body d-flex justify-content-center">
                                <div>
                                <Bar
                                    data={{
                                    labels: [""],
                                    datasets: [
                                        { ...DATASET_TOTAL },
                                        { ...DATASET_ACTIVE },
                                        { ...DATASET_INACTIVE },
                                    ]
                                    }}
                                    options={options}
                                    style={{ width: '400px' }}
                                />
                                </div>
                            </div>
                        </div>

                        <div className="card mt-4">
                            <div className="card-header">
                                <div className="row align-items-center">
                                <div className="d-flex justify-content-between">
                                    <h4 className="m-0">Dispositivos</h4>
                                    <a href="/devices"><button className="btn btn-primary button">Gerenciar</button></a>
                                </div>
                                </div>
                            </div>
                            <div className="row my-2 p-4">
                                <div className="w-100">
                                    <input
                                    onChange={(event) => setSearchTerm(event.target.value)}
                                    placeholder="Pesquisar por nome"
                                    className="form-control"
                                    />
                                    <DataTable
                                    columns={columnsDevices}
                                    noDataComponent="Não há dados para mostrar"
                                    data={devices.filter((item) => {
                                        if (searchTerm === "") {
                                            return item;
                                        }else if(item.model.toLowerCase().includes(searchTerm.toLowerCase())){
                                            return item;
                                        }else if(item.speedingValue.toString().toLowerCase().includes(searchTerm.toLowerCase())){
                                            return item;
                                        }else if(item.remarks.toLowerCase().includes(searchTerm.toLowerCase())){
                                            return item;
                                        }
                                    })}
                                    pagination={true}
                                    paginationComponentOptions={paginationComponentOptions}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="card mt-4">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="m-0">Alarmes</h4>
                                        <a href="alerts"><button className="btn btn-primary button">Gerenciar</button></a>
                                    </div>
                                </div>
                            </div>
                            <div className='container-filter-reports'>
                                <p><b>Filtros</b></p>
                                <Filter onClick={setSelectedFilter} />
                            </div>
                            <DataTable
                                columns={columns}
                                data={alarms}
                                noDataComponent="Não há dados para mostrar"
                                pagination={true}
                                paginationComponentOptions={paginationComponentOptions}
                            />
                        </div>
                        
                        <div className="card mt-4">
                            <div className="card-header">
                                <div className="row align-items-center">
                                <div className="d-flex justify-content-between">
                                    <h4 className="m-0">Últimas notificações enviadas</h4>
                                </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className='container-filter-reports'>
                                <p><b>Filtros</b></p>
                                <Filter onClick={(item) => setSelectedFilterNotification(item)} />
                                </div>
                                <div className="w-100">
                                <input
                                    onChange={(event) => setSearchTerm(event.target.value)}
                                    placeholder="Pesquisar por nome"
                                    className="form-control"
                                />
                                <DataTable
                                    columns={[
                                    { ...COLUMNS.NAME },
                                    { ...COLUMNS.EMAIL },
                                    { ...COLUMNS.NOTIFICATION },
                                    { ...COLUMNS.NOTIFICATION_DATE },
                                    ]}
                                    noDataComponent="Não há dados para mostrar"
                                    data={handleFilter(notifications, selectedFilterNotification, "notificationSentDay", searchTerm)}
                                    pagination={true}
                                    paginationComponentOptions={paginationComponentOptions}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ResponsiveDrawer>
        </div>
    )
}