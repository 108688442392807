import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'
import { useAlert } from 'react-alert';

import { setToken, setUserData } from '../../redux/actions/user';

import LogoMP from "../../assets/img/logo.png"
import "../../index.css"
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

const Login = () => {

  const alert = useAlert();
  const dispatch = useDispatch()

  const requestLogin = async (event) => {
    event.preventDefault()

    var url = process.env.REACT_APP_LINK_API
    let result

    if (url.includes("api/v1/")) {
      result = url.replace("api/v1/", "")
    } else {
      result = url
    }

    const headers = {
      'Content-type': 'application/json'
    }

    const body = {
      email: event.target[0].value,
      password: event.target[1].value
    }

    try {
      let response = await axios.post(result + 'login', body, {
        headers: headers
      })

      if(response.status === 200){
        const headerGetData = {
          'Content-type': 'application/json',
          'Authorization': response.headers.authorization
        }
  
        let responseFetchUserData = await axios.get(url + "user/fetchProfileData", {
          headers: headerGetData
        })
        
        dispatch(setUserData(responseFetchUserData.data));
        dispatch(setToken(response.headers.authorization));
        window.location.reload();
      }
    } catch (e) {
      alert.error("Erro, verifique as informações de acesso")
    }
  }

  return (
    <div className="container-login">
      <div className="flex-row align-items-center ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="card card-login">
              <div className="card-body card-content-login">
                <form onSubmit={requestLogin}>
                  <div className="text-center" id="img-logo-login">
                    <img src={LogoMP} alt="Logo MP Rastreamento"/>
                  </div>
                  <div className="form-container">
                    <h4 className="my-2 mt-4">Login</h4>
                    <p className="text-muted">Entre com suas credenciais</p>
                    <div className="input-group mt-3">
                      <span className="input-group-text rounded-left"><FontAwesomeIcon icon={faUser} /></span>
                      <input type="email" className="form-control rounded-right" placeholder="Digite seu Email" />
                    </div>
                    <div className="input-group mt-3 mb-2">
                      <span className="input-group-text rounded-left"><FontAwesomeIcon icon={faLock} /></span>
                      <input type="password" className="form-control rounded-right" placeholder="Digite sua Senha" />
                    </div>
                    <Link to="/forgot-password" className="outline-none">Esqueci minha senha</Link>
                    <div className="my-3" >
                      <button type="submit" className="border-0 py-2 px-4 rounded button">Acessar</button>
                    </div>
                   
                  </div>
                </form>
              </div>
              <div className="card-content-login">
                <p className="d-none">RASTREAR, PROTEGER, POUPAR!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default Login;