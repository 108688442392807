import { LineWave } from 'react-loader-spinner';
function Loading() {
    return(
        <div className="d-flex align-items-center justify-content-center vh-100">
            <LineWave
                color="blue"
                height={110}
                width={110}
                ariaLabel="three-circles-rotating"
            />
        </div>
       
    )
}

export default Loading;
