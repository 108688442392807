import React from 'react';
import '../styles/filter.css';

export default function Filter({ onClick }) {
  const filters = [{
    label: "Ontem",
    value: ["previous", "days"]
  }, {
    label: "Semana",
    value: ["current", "week"]
  }, {
    label: "Semana Passada",
    value: ["previous", "week"]
  }, {
    label: "Mês",
    value: ["current", "month"]
  }, {
    label: "Mês Passado",
    value: ["previous", "month"]
  }, {
    label: "Ano",
    value: ["current", "year"]
  }, {
    label: "Ano Passado",
    value: ["previous", "year"]
  }];

  return (
    <div>
      <ul className="filter-desktop">
        {
          filters.map((item, index) => <li onClick={() => onClick(item)} key={index}>{item.label}</li>)
        }
      </ul>

      <select className="input-group filter-mobile">
        {
          filters.map((item, index) => (
            <option 
              onClick={() => onClick(item)} 
              key={index}
            >
              {item.label}
            </option>
          ))
        }
      </select>
    </div>
  )
}
