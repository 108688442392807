import API from './config';

const Message = {
  create: async(params) => {
    try {
      let response = await API().post(`/message/createMessage`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data || "Request error." };
    }
  },
  getAll: async () => {
    try {
      let response = await API().get(`/message/findAll`);
        
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByChatId: async (chatId) => {
    try {
      let response = await API().get(`/message/findByChat/${chatId}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getById: async (id) => {
    try {
      let response = await API().get(`/message/findById/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByReceiver: async (userId) => {
    try {
      let response = await API().get(`/message/findByReceiver/${userId}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getBySender: async (userId) => {
    try {
      let response = await API().get(`/message/findBySender/${userId}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  update: async (params) => {
    try {
      let response = await API().put(`/message/updateMessage`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error."};
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/message/delete/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
}

export default Message;
