import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { useAlert } from 'react-alert';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { Filter, ResponsiveDrawer, FilterDataReports, CardReport } from "../../components";
import { COLUMNS, CARDS_TITLES } from "../../utils";
import Button from "./Button";
import Report from '../../api/Report';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import AddCircleSharp from '@mui/icons-material/AddCircleSharp';
import EventAvailable from '@mui/icons-material/EventAvailable';
import DeleteForever from '@mui/icons-material/DeleteForever';
import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';

import 'chart.js/auto';
import './style.css';
import User from '../../api/Users';
import Device from '../../api/Devices';
dayjs.extend(localeData)

export default function Reports() {

    const alert = useAlert();

    const [modalVisible, setModalVisible] = useState(false);
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
    const [modalBulkDeleteVisible, setModalBulkDeleteVisible] = useState(false);
    const [modalScheduleVisible, setModalScheduleVisible] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("");
    const [quilometrage, setQuilometrage] = useState(0);
    const [speed, setSpeed] = useState(0);
    const [stops, setStops] = useState(0);
    const [scheduleActive, setScheduleActive] = useState(false);
    const [uniqueTaskActive, setUniqueTaskActive] = useState(false);
    const [newActive, setNewActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [deviceInformations, setDeviceInformations] = useState();
    const [reportType, setReportType] = useState("");
    
    /*----- DEFINE CARDS QUANTITY STATES ------*/
    const [excessiveSpeed, setExcessiveSpeed] = useState(0);
    const [alarmsReport, setAlarmsReport] = useState();
    const [drivingBehaviorReportData, setDrivingBehaviorReportData] = useState();
    const [ignitionReportData, setIgnitionReportData] = useState();
    const [routeExcSpeedReport, setRouteExcSpeedReport] = useState();
    const [routeReportData, setRouteReport] = useState();
    const [vehicleInformationReportData, setVehicleInformationReportData] = useState();
    

    const getInformationsProfile = async () => {
        let response = await User.getUserData();
        if (response.status !== 'error') {
            setProfileInformations(response.data)
            let responseDevice = await Device.getByUser(response.data.id)
           
            if (responseDevice !== 'error') {
                setDeviceInformations(response.data);
            } 
        } else {
            alert.error(response.message)
        }
    }

    const getAlarmsReports = async (id, start, end) => {
        let response = await Report.alarmReport(id, start, end);

        if(response.status === 'error') {
            setAlarmsReport(response.data);
        }
    }

    const getDrivingBehaviorReport = async (id, start, end) => {
        let response = await Report.drivingBehaviorReport(id, start, end);

        if(response.status !== 'error') {
            setDrivingBehaviorReportData(response.data)
        }
    }

    const getIgnitionReport = async (id, start, end) => {
        let response = await Report.igninitionReport(id, start, end);

        if(response.status !== 'error') {
            setIgnitionReportData(response.data);
        }
    }

    const getRouteExcSpeedReport = async (id, start, end) => {
        let response = await Report.routeExcSpeedReport(id, start, end);

        if(response.status !== 'error') {
            setRouteExcSpeedReport(response.data);
        }
    }

    const getRouteReport = async (id, start, end) => {
        let response = await Report.routeReport(id, start, end);

        if(response.status !== 'error') {
            setRouteReport(response.data);
        }
    }

    const getVehicleInformations = async (id, start, end) => {
        let response = await Report.vehicleInformationReport(id, start, end);

        if(response.status !== 'error') {
            setVehicleInformationReportData(response.data);
        }
    }
    
    const columns = [
        { ...COLUMNS.ID_REPORT },
        { ...COLUMNS.MILEAGE },
        { ...COLUMNS.EXCESSIVE_SPEED },
        { ...COLUMNS.STOPS },
        {
            name: 'Excluir',
            cell: index => {
                return (
                    <div>
                        <button className="btn" title="Excluir" onClick={() => {
                            setModalDeleteVisible(true);
                        }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>

                )
            },
            sortable: true
        },
    ];

    const columnsDelete = [
        { ...COLUMNS.NAME },
        { ...COLUMNS.EMAIL },
        { ...COLUMNS.NOTIFICATION },
        { ...COLUMNS.NOTIFICATION_DATE },

        {
            name: '#',
            selector: row => row.idReport,
            sortable: true
        },
        {
            name: 'Quilometragem (Km)',
            selector: row => row.quilometrage,
            sortable: true
        },
        {
            name: 'Velocidade excessiva (Vezes)',
            selector: row => row.fast,
            sortable: true
        },
        {
            name: 'Paradas (Vezes)',
            selector: row => row.stop,
            sortable: true
        },
        {
            name: 'Selecionar',
            cell: index => {
                return (
                    <div>
                        <input type="checkbox" />
                    </div>
                )
            },
            sortable: true
        },
    ];

    const operationStatistics = [
        'Estatísticas de Operação',
        'Visão global de movimento',
        'Relatório de quilometragem',
        'Detalhes sobre excesso de velocidade',
        'Detalhes das paradas'
    ];

    const routeReport = [
        'Relatório de Percurso',
        'Relatório Percurso em exc. velocidade'
    ];

    const drivingBehaviorReport = [
        'Relatório de comportamento de condução',
        'Visão geral do comportamento de condução',
        'Detalhes de comportamamento de condução'
    ];

    const ignitionReport = [
        'Relatório Ignição',
        'Visão geral do mecanismo',
        'Relatório de inatividade do mecanismo'
    ];

    const vehicleInformationReport = [
        'Relatório de combustível',
        'Relatório de pressão de pneu'
    ];

    const OBDreport = [
        'Visão geral do consumo de combustível',
        'Detalhes de consumo de combustível',
        'Relatório do DTC'
    ];

    const alarmReport = [
        'Visão geral dos alarmes',
        'Detalhe de Alarme',
        'Geo-fence alert report'
    ];

    const defaultOptionOpertationStatistics = operationStatistics[0];
    const defaultOptionRouteReport = routeReport[0];
    const defaultOptionDrivingBehaviorReport = drivingBehaviorReport[0];
    const defaultOptionIgnitionReport = ignitionReport[0];
    const defaultOptionVehicleInformationReport = vehicleInformationReport[0];
    const defaultOptionOBDreport = OBDreport[0];
    const defaultOptionAlarmReport = alarmReport[0];

    const [reportList, setReportList] = useState();
    const [profileInformations, setProfileInformations] = useState([])

    const style_edition = {
        maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
    };

    const addReport = (id, Kms, speed, stops) => {
        let form = {
            "idReport": id,
            "quilometrage": Kms,
            "fast": speed,
            "stop": stops
        }
        //data.push(form);
    }

    const data = [];
    const tableData = {
        columns
    };

    useEffect(() => {
        getInformationsProfile();
    }, [])

    useEffect(() => {
        getInformationsProfile();
    }, [])

    useEffect(() => {
        if (['Visão geral dos alarmes', 'Detalhe de Alarme', 'Geo-fence alert report'].includes(reportType)) {
            getAlarmsReports()
        } else if (['Relatório de comportamento de condução', 'Visão geral do comportamento de condução', 'Detalhes do comportamento de condução'].includes(reportType)) {
            getDrivingBehaviorReport()
        } else if (['Relatório Ignição', 'Visão geral do mecanismo', 'Relatório de inatividade do mecanismo'].includes(reportType)) {
            getIgnitionReport()
        } else if (['Relatório Percurso em exc. velocidade'].includes(reportType)) {
            getRouteExcSpeedReport()
        } else if (['Relatório de percurso']) {
            getRouteReport()
        } else if (['Relatório de pressão do pneu', 'Relatório de combustível']) {
            getVehicleInformations()
        }
    }, [reportType]);
    
    return (
        <div>
            <ResponsiveDrawer>
                <div className="row d-flex pt-4">
                    {
                        menuActive &&
                        (
                            <div className="menu-report">
                                <button className="bg-danger text-white rounded border-0 px-4 py-2 mb-4" onClick={() => setMenuActive(false)}><Close/></button>
                                <Dropdown options={operationStatistics} onChange={(event) => setReportType(event.value)} value={defaultOptionOpertationStatistics} placeholder="Selecione uma opção" />
                                <Dropdown options={routeReport} onChange={(event) => setReportType(event.value)} value={defaultOptionRouteReport} placeholder="Selecione uma opção" />
                                <Dropdown options={drivingBehaviorReport} onChange={(event) => setReportType(event.value)} value={defaultOptionDrivingBehaviorReport} placeholder="Selecione uma opção" />
                                <Dropdown options={ignitionReport} onChange={(event) => setReportType(event.value)} value={defaultOptionIgnitionReport} placeholder="Selecione uma opção" />
                                <Dropdown options={vehicleInformationReport} onChange={(event) => setReportType(event.value)} value={defaultOptionVehicleInformationReport} placeholder="Selecione uma opção" />
                                <Dropdown options={OBDreport} onChange={(event) => setReportType(event.value)} value={defaultOptionOBDreport} placeholder="Selecione uma opção" />
                                <Dropdown options={alarmReport} onChange={(event) => setReportType(event.value)} value={defaultOptionAlarmReport} placeholder="Selecione uma opção" />
                            </div>
                        )
                    }

                    <FilterDataReports>
                        {
                            !menuActive &&
                            (
                                <button className="border-0 mw-100 menu-icon-button" onClick={() => setMenuActive(true)}><MenuIcon /></button>
                            )
                        }
                    </FilterDataReports>

                    <div className="cards">
                        {
                            reportType === "" && (
                                CARDS_TITLES.OVERVIEW_REPORT.map((title) => {
                                    return (
                                        <CardReport title={title} quantity={excessiveSpeed} />
                                    )
                                })
                            )
                        }

                        {
                            reportType === "Visão geral do comportamento de condução" && (
                                CARDS_TITLES.BEHAVIOR_OVERVIEW.map((title) => {
                                    return (
                                        <CardReport title={title} quantity={0} />
                                    )
                                })
                            )
                        }

                        {
                            reportType === "Visão geral do mecanismo" && (
                                CARDS_TITLES.ENGINE_OVERVIEW.map((title) => {
                                    return (
                                        <CardReport title={title} quantity={0} />
                                    )
                                })
                            )
                        }

                        {
                            reportType === "Detalhes de consumo de combustível" && (
                                CARDS_TITLES.FUEL_CONSUMPTION_DETAILS.map((title) => {
                                    return (
                                        <CardReport title={title} quantity={0} />
                                    )
                                })
                            )
                        }

                        {
                            reportType === "Detalhes de consumo de combustível" && (
                                CARDS_TITLES.FUEL_CONSUMPTION_DETAILS.map((title) => {
                                    return (
                                        <CardReport title={title} quantity={0} />
                                    )
                                })
                            )
                        }

                        {
                            reportType === "Visão geral do consumo de combustível" && (
                                CARDS_TITLES.FUEL_CONSUMPTION_OVERVIEW.map((title) => {
                                    return (
                                        <CardReport title={title} quantity={0} />
                                    )
                                })
                            )
                        }

                        {
                            reportType === "Visão global de movimento" && (
                                CARDS_TITLES.MOVEMENT_OVERVIEW.map((title) => {
                                    return (
                                        <CardReport title={title} quantity={0} />
                                    )
                                })
                            )
                        }

                        {
                            reportType === "Relatório de Percurso" && (
                                CARDS_TITLES.ROUTE_REPORTS_OVERVIEW.map((title) => {
                                    return (
                                        <CardReport title={title} quantity={0} />
                                    )
                                })
                            )
                        }

                        {
                            reportType === "Relatório Percurso em exc. velocidade" && (
                                CARDS_TITLES.ROUTE_REPORT_SPEED.map((title) => {
                                    return (
                                        <CardReport title={title} quantity={0} />
                                    )
                                })
                            )
                        }

                        {
                            (reportType === "Relatório de combustível" || reportType === "Relatório de pressão de pneu") && (
                                CARDS_TITLES.VEHICLE_INFORMATION_REPORT.map((title) => {
                                    return (
                                        <CardReport title={title} quantity={0} />
                                    )
                                })
                            )
                        }
                    </div>


                    <div>
                        <div className='container-filter-reports'>
                            <p><b>Filtros</b></p>
                            <Filter onClick={(item) => setSelectedFilter(item)} />
                        </div>
                        <input
                            placeholder="Pesquisar"
                            className="form-control"
                        />
                        <DataTableExtensions
                            {...tableData}
                            filter={false}
                        >
                            <DataTable
                                noDataComponent="Não há dados para mostrar"
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                            />
                        </DataTableExtensions>

                        <div className='container-float-buttons'>
                            <Button
                                icon={<AddCircleSharp />}
                                name="Adicionar relatório"
                                event={() => setModalVisible(true)}
                            />
                            <Button
                                icon={<EventAvailable />}
                                name="Agendar relatório"
                                event={() => setModalScheduleVisible(true)}
                            />
                            <Button
                                icon={<DeleteForever />}
                                name="Excluir em massa"
                                event={() => setModalBulkDeleteVisible(true)}
                            />
                        </div>
                    </div>

                    <Modal
                        open={modalVisible}
                        onClose={() => {
                            setModalVisible(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style_edition}>
                            <div className="row my-2">
                                <div className="col">
                                    <span className="text-uppercase">Adicionar relatório</span>
                                </div>
                            </div>
                            <form id="userForm" onSubmit={() => { }}>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 my-2">
                                        <label>Quilometragem</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            style={{ marginBottom: '3vh' }}
                                            onChange={(event) => setQuilometrage(event.target.value)}
                                        />

                                        <label>Velocidade excessiva</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            style={{ marginBottom: '3vh' }}
                                            onChange={(event) => setSpeed(event.target.value)}
                                        />

                                        <label>Paradas</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            onChange={(event) => setStops(event.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 my-2">
                                    <button type="button" className="btn btn-success" style={{ height: '40px' }} onClick={() => {
                                        addReport(3, quilometrage, speed, stops)
                                        alert.success("Relatório adicionado com sucesso")
                                        setModalVisible(false);
                                    }}> Adicionar </button>
                                    <button className="btn btn-warning mx-2" style={{ height: '40px' }} onClick={() => {
                                        setModalVisible(false)
                                    }}> Fechar </button>
                                </div>
                            </form>
                        </Box>
                    </Modal>

                    <Modal
                        open={modalDeleteVisible}
                        onClose={() => {
                            setModalDeleteVisible(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style_edition}>
                            <div className="row my-2">
                                <div className="col">
                                    <span className="text-uppercase">Tem certeza que deseja excluir</span>
                                </div>
                            </div>
                            <button className="btn btn-success" style={{ height: '40px' }}> Sim </button>
                            <button className="btn btn-warning mx-2" style={{ height: '40px' }} onClick={() => {
                                setModalDeleteVisible(false)
                            }}> Voltar </button>
                        </Box>
                    </Modal>

                    <Modal
                        open={modalBulkDeleteVisible}
                        onClose={() => {
                            setModalBulkDeleteVisible(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style_edition}>
                            <div className="row my-2">
                                <div className="col">
                                    <span className="text-uppercase">Selecione quais relatórios deseja excluir</span>
                                </div>
                                <DataTable
                                    columns={columnsDelete}
                                    noDataComponent="Não há dados para mostrar"
                                    data={data}
                                    pagination={true}
                                    paginationComponentOptions={paginationComponentOptions}
                                />

                            </div>
                            <button className="btn btn-success" style={{ height: '40px' }} onClick={() => setModalBulkDeleteVisible(false)}> Finalizar </button>
                            <button className="btn btn-warning mx-2" style={{ height: '40px' }} onClick={() => {
                                setModalBulkDeleteVisible(false);
                            }}> Voltar </button>
                        </Box>
                    </Modal>

                    <Modal
                        open={modalScheduleVisible}
                        onClose={() => setModalScheduleVisible(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style_edition}>
                            {
                                newActive &&
                                (
                                    <div className="d-flex flex-row add-report">
                                        <div className="w-50 add-report-config">
                                            <h4><b>Configurações</b></h4>
                                            <label>
                                                *Tipo de relatório
                                                <select>
                                                    <optgroup label="Estatísticas de operação">
                                                        <option>Relatório de quilometragem</option>
                                                        <option>Visão global movimento</option>
                                                        <option>Detalhes das paradas</option>
                                                    </optgroup>
                                                    <optgroup label="Relatório de Percurso">
                                                        <option>Relatório de Percurso</option>
                                                        <option>Relatório Percurso em exc.velocidade</option>
                                                    </optgroup>
                                                </select>
                                            </label>
                                            <label>
                                                Nome
                                                <input type="text" />
                                            </label>

                                            <hr></hr>

                                            <label>
                                                <input type="radio" onClick={() => setScheduleActive(!scheduleActive)} />
                                                Cronograma
                                            </label>
                                            <label>
                                                <input type="radio" onClick={() => setUniqueTaskActive(!uniqueTaskActive)} />
                                                Tarefa única
                                            </label>

                                            {
                                                scheduleActive && (
                                                    <>
                                                        <label>
                                                            Diariamente
                                                            <input type="checkbox" />
                                                        </label>
                                                        <label>
                                                            Semanal
                                                            <input type="checkbox" />
                                                        </label>
                                                    </>
                                                )
                                            }
                                            {
                                                uniqueTaskActive &&
                                                (
                                                    <>
                                                        <span>Intervalo de tempo máximo de um mês</span>

                                                        <label>
                                                            Período
                                                            <select>
                                                                <option>Última hora</option>
                                                                <option>Hoje</option>
                                                                <option>Ontem</option>
                                                                <option>Antes de três dias</option>
                                                                <option>Esta semana</option>
                                                                <option>Semana Passada</option>
                                                                <option>Este Mês</option>
                                                                <option>Mês Passado</option>
                                                            </select>
                                                        </label>
                                                        <label>
                                                            De:
                                                            <input type="datetime-local" />
                                                        </label>
                                                        <label>
                                                            Para:
                                                            <input type="datetime-local" />
                                                        </label>
                                                    </>
                                                )
                                            }
                                            <hr></hr>

                                            <label>
                                                Enviar para email
                                                <input type="checkbox" />
                                            </label>
                                            <div className="d-flex">
                                                <input type="email" />
                                                <button className="border rounded bg-primary text-white">+</button>
                                            </div>
                                            <div>
                                                <label>
                                                    <input type="radio" />
                                                    Excel
                                                </label>
                                                <label>
                                                    <input type="radio" />
                                                    PDF
                                                </label>
                                            </div>
                                        </div>

                                        <div className="w-50">
                                            <h4><b>Lista de dispositivos</b></h4>
                                            <ul>
                                                <li className="list-group-item d-flex">
                                                    <input type="checkbox" />
                                                    <input type="text" placeholder="Alvo" />
                                                </li>
                                                <li className="list-group-item d-flex">
                                                    <input type="checkbox" />
                                                    <p>Grupo padrão</p>
                                                </li>
                                                <li className="list-group-item d-flex">
                                                    <input type="checkbox" />
                                                    <p>BMW 320i</p>
                                                </li>
                                                <li className="list-group-item d-flex">
                                                    <input type="checkbox" />
                                                    <p>BMW G310R</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )
                            }

                            <button className="border rounded bg-primary text-white" onClick={() => setNewActive(true)}>Novo</button>
                            <DataTable
                                columns={columns}
                                noDataComponent="Não há dados para mostrar"
                                data={reportList}
                                pagination={true}
                                paginationComponentOptions={paginationComponentOptions}
                            />
                            <button className="border rounded bg-success text-white">Salvar</button>
                        </Box>
                    </Modal>
                </div>
            </ResponsiveDrawer>
        </div>
    )
}
