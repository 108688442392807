import { useState } from 'react';
import { useAlert } from 'react-alert';
import { User } from '../../api';
import LogoMP from "../../assets/img/logo.png";

const ForgotPassword = () => {
    const alert = useAlert();
    const [email, setEmail] = useState('');
    const [sendedEmail, setSendedEmail] = useState(false);

    function handleChangeEmail(e) {
        setEmail(e.target.value)
    }

    async function handleSendEmail(e) {
        e.preventDefault();
        let response = await User.sendEmail(email);

        if(response.status !== 'error') {
            setSendedEmail(true)
        }
    }

  return (
  <div className="container-login">
    <div className="flex-row align-items-center ">
        <div className="container">
        <div className="row justify-content-center">
            <div className="card card-login">
            <div className="card-body card-content-login">
                <form onSubmit={handleSendEmail}>
                    <div className="text-center" id="img-logo-login">
                        <img src={LogoMP} alt="Logo MP Rastreamento"/>
                    </div>
                    <div className="form-container">
                        {
                            sendedEmail ?
                            <div className="py-4">
                                <h3>Email de recuperação enviado!</h3>
                                <p>Verifique sua caixa de entrada para prosseguir</p>
                            </div>
                            :
                            <>
                                <h4 className="my-2 mt-4">Esqueci minha senha</h4>
                                <p className="text-muted">Informe seu email para recuperação</p>
                                <div className="input-group mt-3">
                                    <input type="email" className="form-control rounded-right" placeholder="Digite seu Email" onChange={handleChangeEmail}/>
                                </div>
                                <div className="my-3" >
                                    <button type="submit" className="border-0 py-2 px-4 rounded button">Recuperar</button>
                                </div>
                            </>
                        }
                    </div>
                </form>
            </div>
            <div className="card-content-login">
                <p className="d-none">RASTREAR, PROTEGER, POUPAR!</p>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
  )
}

export default ForgotPassword;