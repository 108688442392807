import axios from 'axios';
import { store } from "../redux/store";

const API = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_LINK_API,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${store.getState().user.token}`
    }
  });
};

export default API;