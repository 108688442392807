import React from 'react';
import './style.css';

export default function Button(props){

    return(
        <button className='button-float-monitor'>
            {
                props?.icon
            }
            <p>{props?.name}</p>
        </button>
    )
}