import { Filter, ResponsiveDrawer } from "../../components";
import { AlarmHistory } from '../../api';
import { store } from "../../redux/store";

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import './style.css';

export default function Alerts(){
    const [selectedFilter, setSelectedFilter] = useState("");
    const [alarms, setAlarms] = useState([]);
    const typeUser = store.getState().user.data.roles[0];
    const userId = store.getState().user.data.id;

    const columns = [
        {
            name: 'Hora do alarme',
            selector: row => row.created,
            sortable: true
        },
        {
            name: 'Tipo de alarme',
            selector: row => row.alarmType,
            sortable: true
        },
        {
            name: 'Dispositivo',
            selector: row => row.device.model,
            sortable: true
        },
        {
            name: 'Descrição',
            selector: row => row.details,
            sortable: true
        },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
    };

    const getAlarms = async () => {
        let response;

        if(typeUser === "ROLE_DISTRIBUTOR") {
            response = await AlarmHistory.getAll();

            if(response.status !== 'error') {
                setAlarms(response);
            }
        } else if (typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER" || typeUser === "ROLE_MONITOR_ONLY") {
            response = await AlarmHistory.getById(userId);

            if(response.status !== 'error') {
                setAlarms(response);
            }
        }
    }

    useEffect(() => {
        getAlarms();
    }, [])

    return (
        <div>
            <ResponsiveDrawer>
                <div className="row">
                    <div className="col">
                       <div className="card px-4">
                        <div className='container-filter-reports'>
                            <p><b>Filtros</b></p>
                            <Filter onClick={setSelectedFilter} />
                        </div>
                        <DataTable
                            columns={columns}
                            data={alarms}
                            noDataComponent="Não há dados para mostrar"
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                        />
                       </div>
                    </div>
                </div>
            </ResponsiveDrawer>
        </div>
    )
}
