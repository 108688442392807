import API from './config';

const Chat = {
  create: async(params) => {
    try {
      let response = await API().post(`/chat/createChat`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data || "Request error." };
    }
  },
  getAll: async() => {
    try {
      let response = await API().get(`/chat/findAll`);
        
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getById: async(id) => {
    try {
      let response = await API().get(`/chat/findById/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByReceiver: async(userId) => {
    try {
      let response = await API().get(`/findByReceiver/${userId}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getBySender: async(userId) => {
    try {
      let response = await API().get(`/findBySender/${userId}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getBySenderAndReceiver: async(senderUserId, receiverUserId) => {
    try {
      let response = await API().get(`/chat/findBySenderAndReceiver/${senderUserId}/${receiverUserId}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/chat/delete/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
}

export default Chat;
