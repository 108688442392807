import API from './config';

const Report = {
  alarmReport: async (id, start, end) => {
    try {
      let response = await API().get(`/report/alarmsReport/${id}/${start}/${end}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data || "Request error." };
    }
  },
  drivingBehaviorReport: async (id, start, end) => {
    try {
      let response = await API().get(`/report/drivingBehaviorReport/${id}/${start}/${end}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data || "Request error." };
    }
  },
  igninitionReport: async (id, start, end) => {
    try {
      let response = await API().get(`/report/igninitionReport/${id}/${start}/${end}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data || "Request error." };
    }
  },
  routeExcSpeedReport: async (id, start, end) => {
    try {
      let response = await API().get(`/report/routeExcSpeedReport/${id}/${start}/${end}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data || "Request error." };
    }
  },
  routeReport: async (id, start, end) => {
    try {
      let response = await API().get(`/report/routeReport/${id}/${start}/${end}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data || "Request error." };
    }
  },
  vehicleInformationReport: async (id, start, end) => {
    try {
      let response = await API().get(`/report/vehicleInformationReport/${id}/${start}/${end}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data || "Request error." };
    }
  },
}

export default Report;