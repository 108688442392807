import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';

const NavItem = (props) => {
    return(
        <ListItem disablePadding className="item-navBar">
        <Link href={props?.href}>
          <ListItemButton>
            <ListItemIcon>
                {props?.children}
            </ListItemIcon>
            <ListItemText primary={props?.text} className="text-item-navbar"/>
          </ListItemButton>
        </Link>
      </ListItem>
    )
}

export default NavItem;