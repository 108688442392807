import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { store } from "../redux/store";

import Home from "../screens/home";
import Login from "../screens/login";
import Users from "../screens/users";
import AssaS from "../screens/assas";
import Monitor from "../screens/monitor";
import Alerts from "../screens/alerts";
import Reports from "../screens/reports";
import Profile from "../screens/profile";
import EditConfigurations from "../screens/editConfigs";
import Message from "../screens/message";
import Panel from "../screens/panel";
import UserManager from "../screens/userManager";
import Devices from "../screens/devices";
import Loading from "../screens/loading";
import BankSlip from "../screens/bankSlip";

import User from "../api/Users";
import ForgotPassword from "../screens/forgotPassword";
import RecoveryPassword from "../screens/recoveryPassword";
import Notifications from "../screens/notifications";

export default function Root() {
  const typeUser = store.getState().user?.data?.roles[0];

  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(true);

  const validateToken = async () => {
    var bodyFormData = new FormData();

    bodyFormData.append('token', (store.getState().user.token).replace('Bearer ', ''));

    let response = await User.validate(bodyFormData);
    setStatus(response.status);
  }

  useEffect(() => {
    if(store.getState().user.token){
      validateToken();
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, []);

  return (
    <Router>
      <Switch>
        {/* THIS SECTION IS USED WITH REDUX TO CHECK IF USER IS LOGGED */}
        
        {

          (store.getState().user.token && status === 200 && loading === false) ?
            (
              <Switch>
                <Route path="/" exact>
                  <Redirect to="/home" />
                </Route>
                <Route path="/login" exact>
                  {
                    typeUser === "ROLE_DISTRIBUTOR" &&
                    <Redirect to="/home" />
                  }
                  {
                    (typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER" || typeUser === "ROLE_MONITOR_ONLY") && 
                    <Redirect to="/monitor" />
                  }
                </Route>
                {
                  typeUser === "ROLE_DISTRIBUTOR" &&
                  <Route path="/home" exact>
                    <Home />
                  </Route>
                }
                {
                  typeUser === "ROLE_DISTRIBUTOR" &&
                  <Route path="/users" exact>
                    <Users />
                  </Route>
                }
                {
                  typeUser === "ROLE_DISTRIBUTOR" &&
                  <Route path="/notifications" exact>
                    <Notifications />
                  </Route>
                }
                {
                  typeUser === "ROLE_DISTRIBUTOR" &&
                  <Route path="/asaas" exact>
                    <AssaS />
                  </Route>
                }
                {
                  (typeUser === "ROLE_DISTRIBUTOR" || typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER" || typeUser === "ROLE_MONITOR_ONLY") &&
                  <Route path="/monitor" exact>
                    <Monitor />
                  </Route>
                }
                {
                  (typeUser === "ROLE_DISTRIBUTOR" || typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER") &&
                  <Route path="/alerts" exact>
                    <Alerts />
                  </Route>
                }
                {
                  (typeUser === "ROLE_DISTRIBUTOR" || typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER") &&
                  <Route path="/reports" exact>
                    <Reports />
                  </Route>
                }
                {
                  (typeUser === "ROLE_DISTRIBUTOR" || typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER" || typeUser === "ROLE_MONITOR_ONLY") &&
                  <Route path="/profile" exact>
                    <Profile />
                  </Route>
                }
                {
                  typeUser === "ROLE_DISTRIBUTOR" &&
                  <Route path="/configurations" exact>
                    <EditConfigurations />
                  </Route>
                }
                {
                  (typeUser === "ROLE_DISTRIBUTOR" || typeUser === "ROLE_VIRTUAL_ACCOUNT" || typeUser === "ROLE_END_USER") &&
                  <Route path="/messages" exact>
                    <Message />
                  </Route>
                }
                {
                  typeUser === "ROLE_DISTRIBUTOR" &&
                  <Route path="/manager" exact>
                    <Panel />
                  </Route>
                }
                {
                  typeUser === "ROLE_DISTRIBUTOR" &&
                  <Route path="/users-manager" exact>
                    <UserManager />
                  </Route>
                }
                {
                  typeUser === "ROLE_DISTRIBUTOR" &&
                  <Route path="/devices" exact>
                    <Devices />
                  </Route>
                }
                {
                  typeUser === "ROLE_DISTRIBUTOR" &&
                  <Route path="/contas" exact>
                    <BankSlip />
                  </Route>
                }
              </Switch>
            ) : (
              <Switch>
                <Route path="/" exact>
                  <Redirect to="/login" />
                </Route>
                <Route path="/login" exact>
                  <Login />
                </Route>
                <Route path="/forgot-password" exact>
                  <ForgotPassword />
                </Route>
                <Route path="/recoverPassword/token/:token" exact>
                  <RecoveryPassword />
                </Route>
            
                <Route path="/home" exact>
                  <Loading />
                </Route>
              </Switch>
            )
        }
      </Switch>
    </Router>
  )
}
