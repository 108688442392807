import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Add from '@mui/icons-material/Add';

import Device from '../../../api/Devices';

export default function ModalEditar(props) {
  const alert = useAlert();

  const [selectFile, setSelectFile] = useState(null);
  const [image, setImage] = useState(null);
  const [model, setModel] = useState("");
  const [typeDevice, setTypeDevice] = useState("");
  const [imeiId, setImeiId] = useState("");
  const [vehiclePartNumberDevice, setVehiclePartNumberDevice] = useState("");
  const [remarks, setRemarks] = useState("");
  const [deviceData, setDeviceData] = useState([]);

  const style_edition = {
    maxWidth: '100vw',
    maxHeight: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto'
  };

  const handleFileChange = e => {
    var file = e.target.files[0];
    var reader = new FileReader();

    reader.onloadend = function () {
      setSelectFile(reader.result);
    }

    if (file) {
      reader.readAsDataURL(file);
    } 
  }

  const updateDevice = async () => {
    let form = {
      ...deviceData,
      "imeiId": imeiId || deviceData.imeiId,
      "model": model || deviceData.model,
      "remarks": remarks || deviceData.remarks,
      "type": typeDevice || deviceData.typer,
      "vehiclePartNumber": vehiclePartNumberDevice || deviceData.vehiclePartNumber
    }

    let response = await Device.update(form)

    if(response.status === 'error'){
      alert.error("Erro na requisição")
    }else{
      alert.success("Dispositivo atualizado com sucesso");
      window.location.reload();
    }
  }

  useEffect(() =>  {
    setDeviceData(props?.data);
    setModel(deviceData.model);
    setTypeDevice(deviceData.type);
    setImeiId(deviceData.imeiId);
    setVehiclePartNumberDevice(deviceData.vehiclePartNumber);
    setRemarks(deviceData.remarks);
    

  }, [props?.data]);

  return(
    <Modal
    open={props.visible}
    onClose={() => props.setModalVisible(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style_edition}>
      <div className="row my-2">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-12">
              <h4 className="m-0">Editar dispositivo</h4>
            </div>
          </div>
        </div>

        <div className="card-body">
          <form id="userForm" onSubmit={() => {}}>
            <div className="row">
              <div >
                <input
                  type="file"
                  id="file"
                  className="input"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                      handleFileChange(event)
                      setImage(event)
                  }}
                />

                <div className="upload-photo">
                  <label for="file" id="label-file">
                  <Add />
                  <p>Ícone</p>
                  </label>
                </div>

                <label>Modelo do veículo</label>
                <input
                  required
                  type="text"
                  defaulValue={model}
                  value={model}
                  className="form-control mb-4"
                  onChange={(event) => setModel(event.target.value)}
                />

                <label>Tipo</label>
                <select  className="form-control" value={typeDevice} defaultValue={typeDevice}>
                  <option onClick={() => setTypeDevice("Motocicleta")}>Motocicleta</option>
                  <option  onClick={() => setTypeDevice("Carro")}>Carro</option>
                </select>

                <label>IMEI</label>
                <input
                  required
                  type="text"
                  className="form-control mb-4"
                  defaultValue={imeiId}
                  value={imeiId}
                  onChange={(event) => setImeiId(event.target.value)}
                />

                <label>Número de registro do veículo</label>
                <input
                  required
                  type="text"
                  className="form-control mb-4"
                  defaultValue={vehiclePartNumberDevice}
                  value={vehiclePartNumberDevice}
                  onChange={(event) => setVehiclePartNumberDevice(event.target.value)}
                />

                <label>Observações</label>
                <textarea
                  required
                  type="text"
                  className="form-control mb-4"
                  defaultValue={remarks}
                  value={remarks}
                  onChange={(event) => setRemarks(event.target.value)}
                />
              </div>
            </div>        

            <div className="col-md-6 col-sm-12 my-2">
              <button type="button" className="btn btn-success mb-4" onClick={() => {
                updateDevice();
                props.setModalVisible(false);
              }}> Salvar </button>
               <button className="btn btn-warning mx-2 mb-4" onClick={() => {props.setModalVisible(false)}}> Fechar </button>
            </div>
          </form>
        </div>
      </div>
    </Box>
  </Modal>
  )
}