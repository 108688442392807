import ResponsiveDrawer from "../../components/ResponsiveDrawer";

import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import InputMask from 'react-input-mask';
import validator from 'validator';
import { useStore } from 'react-redux';
import Modal from 'react-modal';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import AddCircleSharp from '@mui/icons-material/AddCircleSharp';

import User from '../../api/Users';

import './styles.css';

const Profile = () => {

  const alert = useAlert();
  const store = useStore();

  let userData = store.getState().user.data;
 
  const [emailError, setEmailError] = useState('');
  const [src, selectFile] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [profile, setProfile] = useState("");
  const [image, setImage] = useState(null);
  const [cep, setCep] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [urlProfile, setUrlprofile] = useState("");
  const [cropperPhoto, setCropperPhoto] = useState();
  const [srcFileImage, setSrcFileImage] = useState(null);

  const [dataCep, setDataCep] = useState({}); 
  const [city, setCity] = useState("");
  const [uf, setUf] = useState("");
  const [complementAddress, setComplementAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [numberAddress, setNumberAddress] = useState("");
  const [street, setStreet] = useState("");

  const doUpload = (event) => {
    let formData = new FormData();
    formData.append("image", event);

    fetch('https://api.imgur.com/3/image', {
      method: 'POST',
      headers: {
        Authorization: "Client-ID a2191559833e11f"
      },
      body: formData,
      redirect: 'follow'
    }).then(data => data.json()).then((result) => {
      setUrlprofile(result.data.link)
    })
  }

  function getRoundedCanvas(sourceCanvas) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    var width = sourceCanvas.width;
    var height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    context.fill();
    return canvas;
  }

  const getCropData = () => {
    if (typeof cropperPhoto !== "undefined") {
      let croppedCanvas = cropperPhoto.getCroppedCanvas();
      let roundedCanvas = getRoundedCanvas(croppedCanvas);
      setSrcFileImage(roundedCanvas.toDataURL());
      setProfile(roundedCanvas.toDataURL());
      roundedCanvas.toBlob(function (data) {
        let file = new File([data], 'image.png')
        doUpload(file)
      })
      setEditOpen(false)
    }
  };


  const handleFileChange = e => {
    var file = e.target.files[0];
    var reader = new FileReader();

    reader.onloadend = function () {
      selectFile(reader.result);
      setProfile(reader.result);
      setEditOpen(true);
    }

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setProfile("");
    }
  }

  const validateEmail = (e) => {
    var email = e.target.value

    if (validator.isEmail(email)) {
      setEmailError('')
    } else {
      setEmailError('Por favor, insira um email válido!')
    }
  }

  const updateProfile = async () => {
    let form = {
      ...userData,
      "email": email || userData.email,
      "firstName": firstName || userData.firstName,
      "lastName": lastName || userData.lastName,
      "phone": phone || userData.phone,
      "cep": dataCep || userData.cep,
      "city": city || userData.city,
      "complement": complementAddress || userData.complement,
      "number": numberAddress || userData.number,
      "state": uf || userData.state,
      "street": street || userData.street
    }
    let response = await User.update(form);

    if(response.status === "error"){
      alert.error("Ocorreu um erro na requisição.");
    }else{
      alert.success("Usuário atualizado com sucesso.")
    }
  }

  const checkCep = async (e) => {
    let response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
    let cepData = await response.json();
    if(response.status === 200){    
      setDataCep(cepData.cep);
      setCity(cepData.localidade);
      setUf(cepData.uf);
      setDistrict(cepData.bairro);
      setComplementAddress(cepData.complemento);
      setStreet(cepData.logradouro);
    }
  }

  const customStyles = {
    content: {
      width: '250px',
      height: '500px',
      top: '50%',
      left: '60%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      overflowX: 'hidden'
    },
  };

  const closeModal = () => {
    setEditOpen(false);
    setProfile("");
  }

  return (
    <div>
      <ResponsiveDrawer>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="row my-2">
              </div>
                <form id="userForm" onSubmit={() => {}}>
                  <div className="row d-flex justify-content-center">
                    <span className="text-uppercase mx-4 text-center"><b>Editar perfil</b>  </span>
                    <div className="col-md-10 col-sm-12 my-2">

                      <input
                        type="file"
                        id="file"
                        className="input"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          handleFileChange(event);
                          setImage(event);
                        }}
                      />
                      {
                        urlProfile === "" ? (
                          <div className="upload-photo">
                            <label for="file" id="label-file">
                              <AddCircleSharp />
                              <p>Foto de perfil</p>
                            </label>
                          </div>
                        ) : (
                          <div className="profile-photo">
                            <label for="file" id="reupload-file">
                              <img src={profile} alt="" className="reupload-photo-img" />
                            </label>
                          </div>
                        )
                      }
  
                      <Modal
                        isOpen={editOpen}
                        style={customStyles}
                      >
                        <div className="cropper-container">
                          <Cropper
                            className="cropper-container"
                            aspectRatio={1}
                            preview=".img-preview"
                            src={profile}
                            viewMode={1}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => setCropperPhoto(instance)}
                          />
                          <button className="btn btn-danger my-2 mx-2 text-center" onClick={getCropData}>
                            Salvar
                          </button>

                          <button className="btn btn-warning my-2 mx-2 text-center" onClick={closeModal}>
                            Fechar
                          </button>
                        </div>
                      </Modal>

                      <label>Conta</label>
                      <input
                        disabled
                        type="text"
                        className="form-control mb-4"
                        placeholder="admin"
                        defaultValue={userData.roles[0] === "ROLE_ADMIN" ? "ADMIN" : userData.roles[0]}
                      />   
                                
                      <div className="d-flex justify-content-between">
                        <label>Nome
                          <input
                            type="text"
                            className="form-control mb-4 line-input"
                            defaultValue={userData.firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                          />  
                        </label>
                        <label>Sobrenome
                          <input
                            type="text"
                            className="form-control mb-4 line-input"
                            defaultValue={userData.lastName}
                            onChange={(event) => setLastName(event.target.value)}
                          />  
                        </label>
                      </div>
                                    
                      <label>Celular</label>
                      <InputMask
                        type="text"
                        name="tel"
                        mask="(99) 99999-9999"
                        className="form-control mb-4 line-input"
                        defaultValue={userData.phone}
                        onChange={(event) => setPhone(event.target.value)}
                      />
                        
                      <label>Telefone</label>
                      <InputMask
                        type="text"
                        name="tel"
                        mask="(99) 99999-9999"
                        className="form-control mb-4 line-input"
                      />
                
                      <div>
                        <div className="container-fieds d-flex justify-content-between">
                          <label>CEP
                            <InputMask
                              type="text"
                              name="cep"
                              className="form-control mb-4 line-input"
                              onBlur={checkCep}
                              defaultValue={userData.cep}
                            />
                          </label>
                                            
                          <label>Rua
                            <input
                              type="text"
                              className="form-control mb-4 line-input"
                              defaultValue={userData.street}
                              value={street}
                              onChange={(event) => setStreet(event.target.value)}
                            />
                          </label>
                        </div>

                        <div className="container-fieds d-flex justify-content-between">
                          <label>Número
                            <input
                              type="number"
                              min="0"
                              className="form-control mb-4 line-input"
                              defaultValue={userData.number}
                              onChange={(event) => setNumberAddress(event.target.value)}
                            />
                          </label>
                                            
                          <label>Bairro
                            <input
                              type="text"
                              className="form-control mb-4 line-input"
                              defaultValue={userData.neighborhood}
                              value={district}
                              onChange={(event) => setDistrict(event.target.value)}
                            />
                          </label>    
                        </div>

                        <div className="container-fieds d-flex justify-content-between">
                          <label>Cidade
                            <input
                              type="text"
                              className="form-control mb-4 line-input"
                              value={city}
                              onChange={(event) => setCity(event.target.value)}
                              defaultValue={userData.city}
                            />
                          </label>
                        
                          <label>Estado
                            <input
                              className="form-control mb-4 line-input"
                              value={uf}
                              onChange={(event) => setUf(event.target.value)}
                              defaultValue={userData.state}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="container-fieds d-flex justify-content-between">
                        <label>Complemento
                            <input
                              type="text"
                              className="form-control mb-4 line-input"
                              defaultValue={userData.complement}
                              value={complementAddress}
                              onChange={(event) => setComplementAddress(event.target.value)}
                            />
                        </label>
                        <label>Email
                            <input
                              type="email"
                              className="form-control mb-4 line-input"
                              onChange={(e) => {
                                setEmail(e.target.value);
                                validateEmail(e);
                              }}
                              defaultValue={userData.email}
                            />
                        </label>
                      </div>
                      <span className="font-weight-bold text-danger">{emailError}</span>
                             
                      <div className="container-fieds d-flex justify-content-between">
                        <label>Senha
                          <input
                            type="text"
                            className="form-control mb-4 line-input"
                          />
                        </label>
                        <label>Confirmar senha
                          <input
                            type="text"
                            className="form-control line-input"
                          />
                        </label>
                      </div>
                    </div>
                   </div>
                   <div className="col-md-12 col-sm-12 my-2  d-flex justify-content-center">
                     <button type="submit" className="btn btn-success mx-4 btn-sm" onClick={updateProfile}> Salvar </button>
                   </div>
                </form>
              </div>   
            </div>
          </div>
        </ResponsiveDrawer>
    </div>
)
}

export default Profile;
