import React from 'react';

const FilterDataReports = (props) => {
    return(
        <div className="column d-flex p-4">
            {props.children}
            <div className="col-md">
                <span className="mx-2">Data</span>  
                <input type="date" className="ml-2 border border-secondary rounded px-2 py-1"/>   
                <span className="mx-2">Para</span>
                <input type="date" className="ml-2 border border-secondary rounded px-2 py-1"/>
                <button className="border rounded mx-4 button px-4 py-2">Verificar</button>
            </div>
        </div>  
    )
}

export default FilterDataReports;