import React from 'react';
import { useAlert } from 'react-alert';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Device from '../../../api/Devices';

export default function ModalDelete(props){
  const alert = useAlert();
  let idDevice = props?.idDevice;

  const style_edition = {
    maxWidth: '100vw',
    maxHeight: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto'
  };

  const deleteDevice = async () => {
    let response = await Device.delete(idDevice);

    if(response.status === 'error'){
      alert.error("Erro na requisição");
    }else{
      alert.success("Dispositivo deletado com sucesso");
      window.location.reload();
    }
  }

  return(
    <Modal
      open={props.visible}
      onClose={() => props.setModalVisible(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style_edition}>
        <div className="row my-2">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-12">
                <h4 className="m-0">Tem certeza que deseja excluir?</h4>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="col-md-6 col-sm-12 my-2">
              <button type="button" className="btn btn-success btn-sm" onClick={() => { 
                deleteDevice();
                props.setModalVisible(false);
              }}> Confirmar </button>
                <button className="btn btn-warning mx-2 btn-sm" onClick={() => props.setModalVisible(false)}> Voltar </button>
              </div>
            </div>
          </div>
      </Box>
    </Modal>
  )
}
