import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { store } from "../../redux/store";
import { ResponsiveDrawer } from "../../components";

import MessageCard from "./MessageCard";
import CreateChat from './CreateChat';

import { Chat, Message } from '../../api'
import UserTalk from './UserTalk';

import Add from '@mui/icons-material/Add';

import './style.css';

export default function Messages(){

    const [chatsList, setChatList] = useState([]);
    const [userSelected, setUserSelected] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [messages, setMessages] = useState([]);
    const [createdDataChat, setCreatedDataChat] = useState();

    const getChats = async () => {
        let response = await Chat.getAll();
        
        if(response.status !== 'error') {
            setChatList(response);
        }
    }

    function scrollChat() {
        var lastMessages = document.querySelector('.list-messages');
        lastMessages.scroll(0, 100000);
    }

    const sentMessage = async (e) => {
        e.preventDefault();
        let form = {
            "chat": {
                "id": userSelected.id
              },
              "content": messageText,
              "isRead": true,
              "receiver": {
                "id": userSelected.receiver.id,
              },
              "sender": {
                "id": store.getState().user.data.id,
            }
        }

        let response = await Message.create(form);

        if(response.status !== 'error'){
            getMessages(userSelected.id);
            setTimeout(() => {
                scrollChat()
            }, 800)   
        }
    }

   
    const getMessages = async (id) => {
        let response = await Message.getByChatId(id);

        if (response.status !== 'error') {
            setMessages(response);
            
        }
    }

    useEffect(() => {
        getChats();
    }, []);

    return (
        <div>
            <ResponsiveDrawer>
                <div className="message-container d-flex rounded">
                    <div className="user-list-mobile">
                        {
                            chatsList.map((item, index) => (
                                    <UserTalk
                                        key={index}
                                        src={item.receiver.profilePicture || "https://upload.wikimedia.org/wikipedia/commons/7/70/User_icon_BLACK-01.png"}
                                        userName={item.receiver.firstName + " " + item.receiver.lastName}
                                        onclick={() => {
                                            setUserSelected(item);
                                            getMessages(item.id);
                                            setCreatedDataChat(dayjs(item.created).format("DD/MM/YYYY HH:mm:ss"));
                                        }}
                                    />
                                )
                            )
                        }
                         {
                            store.getState().user.data.roles[0] === "ROLE_DISTRIBUTOR" &&
                            (   
                                <>
                                    <button onClick={() => setModalVisible(true)} className="button border-0 p-2 add-chat"><Add/></button>
                                    <CreateChat
                                        visible={modalVisible}
                                        setModalVisible={setModalVisible}
                                    />
                                </>
                            )
                        }
                    </div>
                
                    <div className="user-list">  
                        {
                            store.getState().user.data.roles[0] === "ROLE_DISTRIBUTOR" &&
                            (   
                                <>
                                    <button onClick={() => setModalVisible(true)} className="button border-0 p-2 add-chat"><Add/></button>
                                    <CreateChat
                                        visible={modalVisible}
                                        setModalVisible={setModalVisible}
                                    />
                                </>
                            )
                        }

                        <ul>
                            {
                                chatsList.map((item, index) => (
                                        <UserTalk
                                            key={index}
                                            id={item.id}
                                            src={item.receiver.profilePicture || "https://upload.wikimedia.org/wikipedia/commons/7/70/User_icon_BLACK-01.png"}
                                            userName={item.receiver.firstName + " " + item.receiver.lastName}
                                            onclick={() => {
                                                setUserSelected(item);
                                                getMessages(item.id);
                                                setCreatedDataChat(dayjs(item.created).format("DD/MM/YYYY hh:mm:ss"));
                                            }}
                                        />
                                    )
                                )
                            }
                        </ul>
                    </div>
                    <div className={"chat " + userSelected ? "d-block" : "d-none"}>
                        <div className="header-chat">
                            {
                                userSelected && (
                                    
                                    <div className="d-flex">
                                        <img src={userSelected.receiver.profilePicture || "https://upload.wikimedia.org/wikipedia/commons/7/70/User_icon_BLACK-01.png"} alt="user"/>
                                        <div className="d-flex flex-column">
                                            <span>{userSelected.receiver.firstName} {userSelected.receiver.lastName}</span>
                                            <small className="data-chat">{createdDataChat}</small>
                                        </div>
                                    </div>
                                )
                            }
                            
                        </div>
                        
                        <div className="list-messages">
                            {
                                messages.map((item, index) => (
                                    <MessageCard
                                        key={index}
                                        senderId={item.sender.id}
                                        nameSent={item.sender.firstName + " " + item.sender.lastName}
                                        text={item.content}
                                        messageId={item.id}
                                        pictureUrl={item.sender.profilePicture || "https://upload.wikimedia.org/wikipedia/commons/7/70/User_icon_BLACK-01.png"}
                                        getMessages={() => getMessages(userSelected.id)}
                                        dataCreated={item.created}
                                        dataModified={item.modified}
                                    />
                                ))
                            }
                        </div>
                      
                        <form onSubmit={(e) => sentMessage(e)}>
                            <div className="send-message-box">
                                <textarea 
                                    onChange={(event) => setMessageText(event.target.value)}
                                    value={messageText}
                                    required
                                />
                                <button className="button send-button" type="submit"><b>Enviar</b></button>
                            </div>
                        </form>
                    </div>
                </div>
            </ResponsiveDrawer>
        </div>
    )
}
