import API from './config';
import { store } from "../redux/store";

const AssaSAPI = {
  create: async (params) => {
    try {
      let response = await API().post(`/user/createUser`, params);
      return await response;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error."};
    }
  },
  getAllUsers: async () => {
    try {
      let response = await API().get(`/asaas/getAllUsers`);
      return await response;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getAll: async (paramsOffset, paramsLimit) => {
    try {
      let response = await API().get(`/asaas/getClients/${paramsOffset}/${paramsLimit}`);
      return await response;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getById: async (id) => {
    try {
      let response = await API().get(`/user/findById/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  update: async (params) => {
    try {
      let response = await API().post(`/asaas/updateExternalField/`, params,  {
        headers: {
          'Authorization': `${store.getState().user.token}`,
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  updatePassword: async (id, params) => {
    try {
      let response = await API().put(`/user/updatePassword/${id}?password=${params.password}`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message|| "Request error." };
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/user/delete/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getLastNotifiedClients: async () => {
    try {
      let response = await API().get(`/asaas/findLastNotifiedClients`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getInactiveClients: async () => {
    try {
      let response = await API().get(`/asaas/findInactiveClients`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getOverdueClients: async () => {
    try {
      let response = await API().get(`/trackceo/findOverdueClients`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  blockUser: async (params) => {
    try {
      let response = await API().post(`/trackceo/blockUser`, params, {
        headers: {
          'Authorization': `${store.getState().user.token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  unBlockUser: async (params) => {
    try {
      let response = await API().post(`/trackceo/unblockUser`, params,  {
        headers: {
          'Authorization': `${store.getState().user.token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  automaticModeOn: async (params) => {
    try {
      let response = await API().post(`/asaas/enableAutomaticBlock`, params,  {
        headers: {
          'Authorization': `${store.getState().user.token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  automaticModeOff: async (params) => {
    try {
      let response = await API().post(`/asaas/disableAutomaticBlock`, params,  {
        headers: {
          'Authorization': `${store.getState().user.token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  activeNotification: async (params) => {
    try {
      let response = await API().post(`/asaas/changeNotifyOverdue`, params,  {
        headers: {
          'Authorization': `${store.getState().user.token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  unableNotification: async (params) => {
    try {
      let response = await API().post(`/asaas/changeNotifyOverdue`, params,  {
        headers: {
          'Authorization': `${store.getState().user.token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  }
}

export default AssaSAPI;