import React from 'react';
import './style.css';

export default function Button(props){
    return(
        <button className='button-float' onClick={props?.event}>
            {
                props?.icon
            }
            <p>{props?.name}</p>
        </button>
    )
}