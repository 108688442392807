import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { store } from "../../../redux/store";

import { User, Chat } from '../../../api/'

export default function CreateChat(props) {
    const alert = useAlert();

    const userStore = store.getState().user.data;

    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState();

    const getAllUsers = async () => {
        let response = await User.getAll();

        if(response.status !== 'error') {
            setUsers(response.data.users);
        }
    }

    const createChat = async () => {
        let form = {
            "receiver": {
              "id": parseInt(userSelected),
            },
            "sender": {
              "id": userStore.id,
            }
        }

        let response = await Chat.create(form);

        if(response.status !== 'error'){
            alert.success("Chat criado com sucesso");
            props?.setModalVisible(false);
            window.location.reload();
        } else  {
            props?.setModalVisible(false);
            alert.error(response.message.message);
        }
    }

    const style_edition = {
        minWidth: '40vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    };

    useEffect(() => {
        getAllUsers();
    }, []);

    return(
        <Modal
            open={props?.visible}
            onClose={() => props?.setModalVisible(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style_edition}>
                <div className="row my-2">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col-12">
                            <h4 className="m-0">Criar chat</h4>
                            </div>
                        </div>
                    </div>
        
                    <div className="card-body">
                        <form onSubmit={() => {}} className="d-flex row align-items-center justify-content-center">
                            <label>Iniciar conversa com:</label>
                            <select className="p-2 rounded mb-4" onClick={(event) => setUserSelected(event.target.value)}>
                                {
                                    users.map((item, index) => userStore.id !== item .id && (
                                          <option key={index} value={item.id}>{item.firstName + " " + item.lastName}</option>
                                        )
                                    )
                                }
                            </select>
                            <button className="bg-success border-0 rounded p-2 text-white" onClick={createChat} type="button">Iniciar chat</button>
                        </form>
                    </div>
                </div>
            </Box>
        </Modal>

    )    
}
