import API from './config';

const TrackingHistory = {
  create: async (params) => {
    try {
      let response = await API().post(`/tracking/create`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error."};
    }
  },
  getAll: async () => {
    try {
      let response = await API().get(`/tracking/findAll`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getByDevice: async (deviceId) => {
    try {
      let response = await API().get(`/tracking/findByDevice/${deviceId}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getById: async (id) => {
    try {
      let response = await API().get(`/tracking/findById/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  update: async (params) => {
    try {
      let response = await API().put(`/tracking/update`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/tracking/delete/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
}

export default TrackingHistory;
