import ResponsiveDrawer from "../../components/ResponsiveDrawer";

import React, { useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import { useAlert } from 'react-alert';
import SystemConfigurations from '../../api/SystemConfigurations';
export default function EditConfigurations() {
    const alert = useAlert();
    const [informationsSystemConfigurarions, setInformationsSystemConfigurations] = useState([])
    const [distanceMeasurements, setDistanceMeasurements] = useState([])
    const [pressureMetrics, setPressureMetrics] = useState([])
    const [temperatureMetrics, setTemperatureMetrics] = useState([])
    const [volumeMetrics, setVolumeMetrics] = useState([])
    const [fuelEfficiencyMetrics, setFuelEfficiencyMetrics] = useState([])
    const [timezone, setTimezone] = useState([])
    const [firstDayOfTheWeek, setFirstDayOfTheWeek] = useState([])
    const [engineIdle, setEngineIdle] = useState()
    const [passwordCommand, setPasswordCommand] = useState()

    const getInformationsSystemConfigurations = async () => {
        let response = await SystemConfigurations.getAll();
        if (response.status !== 'error') {
            setInformationsSystemConfigurations(response[0])
            setDistanceMeasurements(response[0].distanceMeasurements)
            setPressureMetrics(response[0].pressureMetrics)
            setTemperatureMetrics(response[0].temperatureMetrics)
            setVolumeMetrics(response[0].volumeMetrics)
            setFuelEfficiencyMetrics(response[0].fuelEfficiencyMetrics)
            setTimezone(response[0].timezone)
            setFirstDayOfTheWeek(response[0].firstDayOfTheWeek)
            setEngineIdle(response[0].engineIdle)
            setPasswordCommand(response[0].passwordCommand)
        }
    }

    const createSystemConfigurations = async (event) => {
        event.preventDefault();
        let form = {
            "distanceMeasurements": distanceMeasurements,
            "engineIdle": engineIdle,
            "firstDayOfTheWeek": firstDayOfTheWeek,
            "fuelEfficiencyMetrics": fuelEfficiencyMetrics,
            "passwordCommand": passwordCommand,
            "pressureMetrics": pressureMetrics,
            "temperatureMetrics": temperatureMetrics,
            "timezone": timezone,
            "volumeMetrics": volumeMetrics
        }
        let responseGetSystemConfigurations = await SystemConfigurations.show();
        if (responseGetSystemConfigurations.status === "error") {
            let responseCreateSystemConfigurations = await SystemConfigurations.create(form);
            if (responseCreateSystemConfigurations !== "error") {
                alert.success("Configurações do sistema criada com sucesso!");
                setTimeout(() => {
                    window.location.reload();
                }, 1200);  
            }
        } else {
            form = {
                ...form,
                "id": informationsSystemConfigurarions.id
            }
            let responseUpdateSystemConfigurations = await SystemConfigurations.update(form);
            if (responseUpdateSystemConfigurations.status !== "error") {
                alert.success("Configurações do sistema atualizadas com sucesso!");
                setTimeout(() => {
                    window.location.reload();
                }, 1200); 
            }
        }
    }

    useEffect(() => {
        getInformationsSystemConfigurations();
    }, [])

    return (
        <div>
            <ResponsiveDrawer>
                <div className="row">
                    <div className="col">
                        <div className="card mt-0">
                            <form id="userForm" onSubmit={createSystemConfigurations}>
                                <div className="row d-flex justify-content-center py-2 px-1">
                                    <span className="text-uppercase mx-4 text-center"><b>Preferências</b> [userAdmin]</span>
                                    <div className="col-md-10 col-sm-12 my-2">
                                        <label>Medidas de distância:</label>
                                        <select
                                            className="form-control mb-4"
                                            name="distanceMeasurements"
                                            value={distanceMeasurements}
                                            onChange={(event) => setDistanceMeasurements(event.target.value)}>
                                            <option>Quilômetro</option>
                                            <option >Milha</option>
                                        </select>

                                        <label>Métricas de pressão:</label>
                                        <select
                                            className="form-control mb-4"
                                            name="pressureMetrics"
                                            value={pressureMetrics}
                                            onChange={(event) => setPressureMetrics(event.target.value)}
                                        >
                                            <option>Libras por polegada (PSI)</option>
                                            <option>Barra (Barra)</option>
                                            <option>KiloPascals (kPa)</option>
                                        </select>

                                        <label>Métricas de temperatura:</label>
                                        <select
                                            className="form-control mb-4"
                                            name="temperatureMetrics"
                                            value={temperatureMetrics}
                                            onChange={(event) => setTemperatureMetrics(event.target.value)}>
                                            <option>Celsius (ºC)</option>
                                            <option>Fahrenheit (ºF)</option>
                                        </select>

                                        <label>Métricas de volume:</label>
                                        <select
                                            className="form-control mb-4"
                                            name="volumeMetrics"
                                            value={volumeMetrics}
                                            onChange={(event) => setVolumeMetrics(event.target.value)}>
                                            <option>Litros (L)</option>
                                            <option>Galões (EUA)</option>
                                            <option>Galões (Reino Unido)</option>
                                        </select>


                                        <label>Métricas de eficiência de combustível:</label>
                                        <select
                                            className="form-control"
                                            name="fuelEfficiencyMetrics"
                                            value={fuelEfficiencyMetrics}
                                            onChange={(event) => setFuelEfficiencyMetrics(event.target.value)}>
                                            <option>L/100KM(L)</option>
                                            <option>Milhas / galão (EUA)</option>
                                            <option>Milhas / galão  (Reino Unido)</option>
                                        </select>

                                        <hr></hr>

                                        <label>Fuso horário:</label>
                                        <select
                                            className="form-control mb-4"
                                            name="timezone"
                                            value={timezone}
                                            onChange={(event) => setTimezone(event.target.value)}>
                                            <option>UTC-12:00</option>
                                            <option>UTC-11:00</option>
                                            <option>UTC-10:00</option>
                                            <option>UTC-09:30</option>
                                            <option>UTC-09:00</option>
                                            <option>UTC-08:00</option>
                                            <option>UTC-07:00</option>
                                            <option>UTC-06:00</option>
                                            <option>UTC-05:00</option>
                                            <option>UTC-04:30</option>
                                            <option>UTC-04:00</option>
                                            <option>UTC-03:30</option>
                                            <option>UTC-03:00</option>
                                            <option>UTC-02:00</option>
                                            <option>UTC-01:00</option>
                                            <option>UTC+00:00</option>
                                            <option>UTC+01:00</option>
                                            <option>UTC+02:00</option>
                                            <option>UTC+03:00</option>
                                            <option>UTC+03:30</option>
                                            <option>UTC+04:00</option>
                                            <option>UTC+04:30</option>
                                            <option>UTC+05:00</option>
                                            <option>UTC+05:30</option>
                                            <option>UTC+05:45</option>
                                            <option>UTC+06:00</option>
                                            <option>UTC+06:30</option>
                                            <option>UTC+07:00</option>
                                            <option>UTC+08:00</option>
                                            <option>UTC+08:30</option>
                                            <option>UTC+08:45</option>
                                            <option>UTC+09:00</option>
                                            <option>UTC+09:30</option>
                                            <option>UTC+10:00</option>
                                            <option>UTC+10:30</option>
                                            <option>UTC+11:00</option>
                                            <option>UTC+12:00</option>
                                            <option>UTC+12:45</option>
                                            <option>UTC+13:00</option>
                                            <option>UTC+14:00</option>
                                        </select>
                                        <label>Primeiro dia da semana:</label>
                                        <select
                                            className="form-control"
                                            name="firstDayOfTheWeek"
                                            value={firstDayOfTheWeek}
                                            onChange={(event) => setFirstDayOfTheWeek(event.target.value)}>
                                            <option>Segunda-feira</option>
                                            <option>Domingo</option>
                                        </select>
                                        <hr></hr>
                                        <div className="d-flex flex-column">
                                            <label>Ocioso do motor:</label>
                                            <Toggle
                                                checked={engineIdle}
                                                name='engineIdle'
                                                onChange={(event) => setEngineIdle(event.target.checked)}
                                            />

                                            <label>Comando de senha:</label>
                                            <Toggle
                                                checked={passwordCommand}
                                                name='passwordCommand'
                                                onChange={(event) => setPasswordCommand(event.target.checked)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-12 my-2  d-flex justify-content-center">
                                    <button type="submit" className="btn btn-success btn-sm"> Salvar</button>
                                    <button className="btn btn-warning mx-2 btn-sm"> Cancelar </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </ResponsiveDrawer>
        </div>
    )
}
