export const OVERVIEW_REPORT = ["Quantidades de dispositivos:", "Total Quilometragem (Km):", "Total Velocidade Excessiva (vezes):", "Total de paradas (vezes):"];

export const BEHAVIOR_OVERVIEW = ["Total Fadiga de condução:", "Total Aceleração Brusca:", "Total Desaceleração Brusca:", "Total Curva Acentuada:", "Total Mudança Acentuada:"];

export const ENGINE_OVERVIEW  =  ["Quantidades de dispositivos:", "Total Ignição Ligada:", "Total Ignição Desligada:"];

export const FUEL_CONSUMPTION_DETAILS = ["Total Tempos de viagem:", "Total Quilometragem:", "Total Velocidade Excessiva (vezes):", "Total Tempo de execução:", "Total Consumo de combustível:", "Total Consumo médio de combustível:"];

export const FUEL_CONSUMPTION_OVERVIEW = ["Total Tempos de viagem:", "Total Quilometragem:", "Total Velocidade Excessiva (vezes):", "Total Tempo de execução:", "Total Consumo de combustível:", "Total Consumo médio de combustível:"];

export const MOVEMENT_OVERVIEW = ["Quantidades de dispositivos:", "Total Quilometragem (Km):", "Total Velocidade Excessiva (vezes):", "Total de paradas (vezes):"];

export const ROUTE_REPORTS_OVERVIEW = ["Total Duração da unidade:", "Total Odômetro:", "Total Contagem de estacionamento:", "Total Duração do estacionamento:"];

export const ROUTE_REPORT_SPEED = ["Total Odômetro:", "Total Duração:", "Velocidade mínima:"];

export const VEHICLE_INFORMATION_REPORT = ["Quantidades de dispositivos:", "Total Quilometragem (Km):", "Total Velocidade Excessiva (vezes):", "Total de paradas (vezes):"];