import API from './config';

const SystemConfigurations = {
  create: async (params) => {
    try {
      let response = await API().post(`/config/create`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error."};
    }
  },
  getAll: async (paramsOffset, paramsLimit) => {
    try {
      let response = await API().get(`/config/findAll`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  show: async (id) => {
    try {
      let response = await API().get(`/config/show`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  update: async (params) => {
    try {
      let response = await API().put(`/config/update`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/config/delete/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getLastNotifiedClients: async () => {
    try {
      let response = await API().get(`/asaas/findLastNotifiedClients`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getInactiveClients: async () => {
    try {
      let response = await API().get(`/asaas/findInactiveClients`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getOverdueClients: async () => {
    try {
      let response = await API().get(`/trackceo/findOverdueClients`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
}

export default SystemConfigurations;
