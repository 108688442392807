import * as types from '../actions';

const initialState = {
  data: null,
  token: null,
};

const UserReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOKEN:
      return { ...state, token: action.token };
    case types.SET_USER_DATA:
      return { ...state, data: action.data };
    default:
      return state;
  }
};

export default UserReducers;
