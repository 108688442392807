import API from './config';

const User = {
  create: async(params) => {
    try {
      let response = await API().post(`/user/createUser`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error.", data: e?.response?.data || {} };
    }
  },
  getAll: async() => {
    try {
      let response = await API().get(`/user/fetchAllUserData`); 
      return await response;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getUserData: async() => {
    try {
      let response = await API().get(`/user/fetchProfileData`);  
      return await response;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  getById: async(id) => {
    try {
      let response = await API().get(`/user/findById/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  update: async(params) => {
    try {
      let response = await API().put(`/user/updateUser/`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  updatePassword: async(id, params) => {
    try {
      let response = await API().put(`/user/updatePassword/${id}?password=${params.password}`, params);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  updateForgotPassword: async(password, token) => {
    try {
      let response = await API().post(`/user/updateForgotPassword?password=${password}&token=${token}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error." };
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/user/delete/${id}`);
      return await response.data;
    } catch(e) {
      return { status: 'error', message: e?.response?.data?.message || "Request error."};
    }
  },
  validate: async (params) => {
    try {
      let response = await API().post(`/user/validateToken`, params);
      return await response;
    } catch(e) {
      return e.response;
    }
  },
  validateForgotToken: async (token) => {
    try {
      let response = await API().get(`/user/validateForgotPasswordToken/${token}`);
      return await response;
    } catch(e) {
      return e.response;
    }
  },
  sendEmail: async (email) => {
    try {
      let response = await API().get(`/user/sendForgotPassword/${email}`);
      return await response;
    } catch(e) {
      return e.response;
    }
  },
}

export default User;