import dayjs from "dayjs"

export const NAME = {
  name: 'Nome',
  selector: row => row.name,
  sortable: true
}

export const EMAIL = {
  name: 'Email',
  selector: row => row.email,
  sortable: true
}

export const NOTIFICATION = {
  name: 'Tipo da Notificação',
  selector: row => row.email,
  cell: index => (
    <p>
      {
        index.notificationSent === 1 ? "Anterior ao bloqueio" :
          index.notificationSent === 2 ? "Uma semana após bloqueio" :
            "Duas semanas após bloqueio"
      }
    </p>
  ),
  sortable: true
}

export const NOTIFICATION_DATE = {
  name: 'Data de Envio',
  cell: index => dayjs(index.notificationSentDay, 'YYYY-MM-DD').format('DD-MM-YYYY'),
  sortable: true
}

export const DUE_DATE = {
  id: 'dueDate',
  name: 'Data de vencimento',
  cell: (row) => <div>{dayjs(row.dueDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>,
  sortable: true
}

export const DUE_DAYS = {
  id: 'dueDays',
  name: 'Dias em atraso',
  cell: (row) => <div>{parseInt(dayjs().diff(dayjs(row.dueDate), 'days', true).toFixed(0))} dias</div>,
  sortable: true
}

export const EXTENAL_REFERENCE = {
  name: 'Referência Externa',
  selector: row => row.externalReference,
  sortable: true  
}


export const STATUS = {
  name: 'Status',
  cell:  (row, index, column, id) => {
      return(
          <div>
              <p>{row.active ? 'Ativo' : 'Inativo'}</p>
          </div>
      )
  },
  sortable: true  
}

/**------ LIST REPORT ------ */
export const ID_REPORT = {
  name: '#',
  selector: row => row.idReport,
  sortable: true
}

export const MILEAGE = {
  name: 'Quilometragem (Km)',
  selector: row => row.mileage,
  sortable: true
}

export const EXCESSIVE_SPEED = {
  name: 'Velocidade excessiva (Vezes)',
  selector: row => row.excessiveSpeed,
  sortable: true
}

export const STOPS = {
  name: 'Paradas (Vezes)',
  selector: row => row.stops,
  sortable: true
}

